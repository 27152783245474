import { handleLogOut } from "../../../utils";
import { alert_img } from "../../../utils/images";
import React, { useEffect } from "react";

const SessionExpiredPopup = () => {
  useEffect(() => {
    return () => {
      handleLogOut();
      window.location.reload();
    };
  }, []);

  return (
    <div className="forgot_model_popup">
      <div className="model_popup_detail">
        <h6>Alert</h6>
        <div className="event_popup_close_btn">
        </div>
        <div className="flex justify-center">
          <img src={alert_img} alt="alert_img" />
        </div>
        <p>
          Sorry! It looks like your session has expired. Please log in again to
          continue.
        </p>        
        <div className="events_model_popup_btns flex justify-center mt-6">
          <button
            className="btn_reject btn_cancel btn "
            onClick={() => {
              handleLogOut();
              window.location.reload();
            }}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionExpiredPopup;
