import axiosClient from "../../api/axiosClient";

const contentProgressModel = {
  state: {
    contentList: [],
    resourceList: [],
    currentContent: {},
    guestList: [],
    messageContent: {},
  },
  reducers: {
    setData: (state, payload) => {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async getMentorContentList(payload) {
      await axiosClient
        .post("/content/getContentList", payload)
        .then((res) => {
          if (res.data.code === 200) {
            if (payload.type == "Content") {
              const { contentList } = res.data.data;

              this.setData({
                resourceList: contentList,
              });
            } else {
              const { contentList } = res.data.data;
              this.setData({
                contentList: contentList,
              });
            }
          } else {
            // dispatch.toastModel.showToast({
            //   value: "Danger",
            //   message: res.data.msg,
            // });
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
    async getMentorContentURL(payload) {
      await axiosClient
        .post("/content/getContentURL", payload)
        .then((res) => {
          if (res.data.code === 200) {
            const { redirectURL } = res.data.data;
            let redirectURLWin = window.open(redirectURL, "_blank");
            dispatch.preMatchModel.setData({
              name: "redirectURLWin",
              data: redirectURLWin,
            });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
    async getMenteeContentList(payload) {
      await axiosClient
        .post("/content/getContentList", payload)
        .then((res) => {
          if (res.data.code === 200) {
            if (payload.type == "Content") {
              const { contentList } = res.data.data;

              this.setData({
                resourceList: contentList,
              });
            } else {
              const { contentList } = res.data.data;
              this.setData({
                contentList: contentList,
              });
            }
          } else {
            // dispatch.toastModel.showToast({
            //   value: "Danger",
            //   message: res.data.msg,
            // });
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
    async getMenteeContentURL(payload) {
      await axiosClient
        .post("/content/getContentURL", payload)
        .then((res) => {
          if (res.data.code === 200) {
            const { redirectURL } = res.data.data;
            let redirectURLWin = window.open(redirectURL, "_blank");
            dispatch.preMatchModel.setData({
              name: "redirectURLWin",
              data: redirectURLWin,
            });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
    async getGuestMentees(payload) {
      await axiosClient.post("event/getGuestList", payload).then((res) => {
        if (res.status === 200 && res.data.code !== 404) {
          this.setData({
            guestList: res.data.data.GuestList,
          });
        }
      });
    },
    async shareContent({ payload, handleNavigate }) {
      await axiosClient.post("/content/shareContent", payload).then((res) => {
        if (res.status === 200) {
          // this.setData({
          //   guestList: res.data.data,
          // });
          dispatch.toastModel.showToast({
            value: "Success",
            message: "Your Message has been send successfully",
          });
          if (handleNavigate) {
            handleNavigate("/messages");
          }
        }
      });
    },
    async getContentDetail({ payload, setMsg }) {
      await axiosClient
        .post("/content/getContentDetail", payload)
        .then((res) => {
          if (res.status === 200) {
            this.setData({
              messageContent: res.data.data.getContent,
            });
            // dispatch.toastModel.showToast({
            //   value: "Success",
            //   message: "Your Message has been send successfully",
            // });
            if (setMsg) {
              setMsg("");
            }
          }
        });
    },
    async incrementContentViewedCount(payload) {
      await axiosClient
        .post("/content/incrementContentViewedCount", payload)
        .then((res) => {
          if (res.status === 200) {
          }
        });
    },
  }),
};
export default contentProgressModel;
