import { Navigate } from "react-router-dom";
import axiosClient from "../../api/axiosClient";

const mentorModel = {
  state: {
    mentorsUserList: [],
    mentorsId:"",
    sort: {
      headerType: "",
      sortType: "",
    },
    Payload: {
      search: "",
      sort: "",
      order: "",
      page: 1,
    },
  },
  reducers: {
    setData(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async getMentors(payload) {
      await axiosClient
        .post("mentee/getMentorList", payload)
        .then((res) => {
          if (res.status === 200) {
            this.setData({
                mentorsUserList:res?.data?.data?.docs,
            });
          }
          
        })
        .catch((err) => {
          return err.response;
        });
    },
    async getMentorUserDetail(payload) {
        await axiosClient
          .post("mentee/getMentorUserDetail", payload)
          .then((res) => {
            if (res.status === 200) {
              this.setData({
                  mentorsUserList:res?.data?.data?.docs,
              });
            }
            else{
              <Navigate to="/unauthorized" replace />
            }
          })
          .catch((err) => {
            return err.response;
          });
      },
  }),
};

export default mentorModel;
