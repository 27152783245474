import React, { Suspense, useEffect } from "react";
import { useNavigate } from "react-router";
import StepHeader from "./StepHeader";
import { useSelector } from "react-redux";
import store from "../../rematch";
import Loader from "../Loader";
import { cookieDecryption, onBoardingHandler, stepByPath } from "../../utils";

const OnboardingLayout = ({ children }) => {
  const auth = cookieDecryption("userDetails")
  const navigate = useNavigate();
  const isHideHeader =
    window.location.pathname === "/signup-success" ||
    window.location.pathname === "/application-complete";
  const { dispatch } = store;
  const { userObj } = useSelector(
    (state) => state.onBoardingModel.onboardingSteps
  );
    
  const loading = useSelector(
    (state) => state.loading.effects.onBoardingModel.addOnboardingStep1Exit
  );
  const loadingTwo = useSelector(
    (state) => state.loading.effects.onBoardingModel.addOnboardingStep2Exit
  );
  const loadingThree = useSelector(
    (state) => state.loading.effects.onBoardingModel.addOnboardingStep3Exit
  );

  useEffect(() => {
    if (auth && stepByPath(window.location.pathname) > auth.onboardingStep) {
      navigate(onBoardingHandler(auth.onboardingStep));
    }
  }, [])


  const handleNavigate = (path) => {
    navigate(path);
  };

  const getNextPath = (step) => {
    switch (step) {
      case 0: {
        return "/signup-success";
      }
      case 1: {
        return "/basic-info";
      }
      case 2: {
        return "/additional-info";
      }
      case 3: {
        return "/matching-questions";
      }
    }
  };

  const onStepClick = (step) => {
    if (step.completed || step.id === userObj.onboardingStep) {
      handleNavigate(getNextPath(step.id));
    }
  };

  const onSave = () => {
    dispatch.onBoardingModel.setData({ name: "isSaveClicked", data: true });
  };

  return (
    <section className={`main_sec_space ${(auth?.onboardingStep == 4 ) ?"complete_box":""}`}>
      {!isHideHeader && (
        <StepHeader
          loading={loading || loadingTwo || loadingThree}
          onSave={onSave}
          handleNavigate={onStepClick}
          userObj={userObj}
        />
      )}
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </section>
  );
};

export default OnboardingLayout;
