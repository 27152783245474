import moment from "moment";
import axiosClient from "../../api/axiosClient";
import SessionExpiredPopup from "../../components/Popup/SessionExpiredPopup";
const rerenderCalendar = (dispatch, state, res, type) => {
  dispatch.calendarModel.getAllEvents();
  const selectedDate = state.calendarModel.selectedDate;
  switch (type) {
    case "add":
      const {
        _id,
        note,
        remind_time: remind_date,
        title: reminder_name,
      } = res.data.data;
      if (selectedDate) {
        const resDate = moment(remind_date).format("M/D/YYYY");
        if (selectedDate === resDate) {
          const updatedArray = [
            ...state.calendarModel.dateSpecifiedEvents,
            { _id, note, remind_date, reminder_name, type: "Reminder" },
          ].sort((a, b) => {
            const dateA =
              a.type === "Event"
                ? new Date(a.start_date)
                : new Date(a.remind_date);
            const dateB =
              b.type === "Event"
                ? new Date(b.start_date)
                : new Date(b.remind_date);
            return dateA - dateB;
          });
          dispatch.calendarModel.setData({
            name: "dateSpecifiedEvents",
            data: updatedArray,
          });
        } else {
          return;
        }
      } else {
        const updatedArray = [
          ...state.calendarModel.dateSpecifiedEvents,
          { _id, note, remind_date, reminder_name, type: "Reminder" },
        ].sort((a, b) => {
          const dateA =
            a.type === "Event"
              ? new Date(a.start_date)
              : new Date(a.remind_date);
          const dateB =
            b.type === "Event"
              ? new Date(b.start_date)
              : new Date(b.remind_date);
          return dateA - dateB;
        });
        dispatch.calendarModel.setData({
          name: "dateSpecifiedEvents",
          data: updatedArray,
        });
      }
      break;
    case "update":
      if (selectedDate) {
        const {
          _id,
          note,
          remind_time: remind_date,
          title: reminder_name,
        } = res.data.data;
        const resDate = moment(remind_date).format("M/D/YYYY");
        if (selectedDate === resDate) {
          const sameEvent = state.calendarModel.dateSpecifiedEvents.findIndex(
            (event) => event?._id === res.data.data._id
          );
          if (sameEvent > -1) {
            const updatedArray = [...state.calendarModel.dateSpecifiedEvents];
            updatedArray[sameEvent] = {
              _id,
              note,
              remind_date,
              reminder_name,
              type: "Reminder",
            };
            updatedArray.sort((a, b) => {
              const dateA =
                a.type === "Event"
                  ? new Date(a.start_date)
                  : new Date(a.remind_date);
              const dateB =
                b.type === "Event"
                  ? new Date(b.start_date)
                  : new Date(b.remind_date);
              return dateA - dateB;
            });
            dispatch.calendarModel.setData({
              name: "dateSpecifiedEvents",
              data: updatedArray,
            });
          } else {
            const updatedArray = [
              ...state.calendarModel.dateSpecifiedEvents,
              { _id, note, remind_date, reminder_name, type: "Reminder" },
            ].sort((a, b) => {
              const dateA =
                a.type === "Event"
                  ? new Date(a.start_date)
                  : new Date(a.remind_date);
              const dateB =
                b.type === "Event"
                  ? new Date(b.start_date)
                  : new Date(b.remind_date);
              return dateA - dateB;
            });
            dispatch.calendarModel.setData({
              name: "dateSpecifiedEvents",
              data: updatedArray,
            });
          }
          // const updated = state.calendarModel.dateSpecifiedEvents.map((event)=>{
          //   if (event?._id === res.data.data._id){
          //     return { _id, note: }
          //   }
          // })
          // console.log(res.data.data);
          // console.log(state.calendarModel.dateSpecifiedEvents);
        } else {
          const updatedDateSpecifiedEvents =
            state.calendarModel.dateSpecifiedEvents.filter(
              (event) => event._id !== res.data.data._id
            );
          dispatch.calendarModel.setData({
            name: "dateSpecifiedEvents",
            data: updatedDateSpecifiedEvents,
          });
        }
      } else {
        const updated = state.calendarModel.dateSpecifiedEvents.map((event) => {
          const {
            _id,
            note,
            remind_time: remind_date,
            title: reminder_name,
          } = res.data.data;
          if (event?._id === res.data.data._id) {
            return { _id, note, remind_date, reminder_name, type: "Reminder" };
          } else {
            return {
              ...event,
            };
          }
        });
        updated.sort((a, b) => {
          const dateA =
            a.type === "Event"
              ? new Date(a.start_date)
              : new Date(a.remind_date);
          const dateB =
            b.type === "Event"
              ? new Date(b.start_date)
              : new Date(b.remind_date);
          return dateA - dateB;
        });
        dispatch.calendarModel.setData({
          name: "dateSpecifiedEvents",
          data: [...updated],
        });
        // console.log(res.data.data);
        // console.log(state.calendarModel.dateSpecifiedEvents);
      }
      break;
    case "delete":
      const updatedDateSpecifiedEvents =
        state.calendarModel.dateSpecifiedEvents.filter(
          (event) => event._id !== res.data.data._id
        );
      dispatch.calendarModel.setData({
        name: "dateSpecifiedEvents",
        data: updatedDateSpecifiedEvents,
      });
      break;

    default:
      break;
  }
};
const homeModel = {
  state: {
    announcementMessage: [],
    reminderEditData: {},
    assignProjectList: [],
    reminderPopup: false,
    matchedPopup: false,
    matchedData: {},
    redirectURLWin: "",
    resizeWidth: false,
    isCookieAccepted: false,
  },

  reducers: {
    setData(state, payload) {
      const { name, data } = payload;
      return { ...state, [name]: data };
    },
  },
  effects: (dispatch) => ({
    async getAnnouncementMessage() {
      await axiosClient
        .get("home/getAnnouncementMessage")
        .then((res) => {
          // dispatch.dialogModel.showDialog({
          //   component: () => <SessionExpiredPopup />,
          //   className: "",
          // });
          if (res.status === 200) {
            this.setData({
              name: "announcementMessage",
              data: [...res.data.data],
            });
          }
        })
        .catch((err) => {
          return err.response;
        });
    },

    async setNewReminder(payload, state) {
      await axiosClient
        .post("/home/setNewReminder", payload)
        .then((res) => {
          if (res.status === 200) {
            const updatedannouncementList =
              state.homeModel.announcementMessage.map((announcement) => {
                if (announcement._id == res.data.data.messageId) {
                  return { ...announcement, isReminder: true };
                }
                return announcement;
              });
            this.setData({
              name: "reminderEditData",
              data: { ...res.data.data },
            });
            this.setData({
              name: "announcementMessage",
              data: [...updatedannouncementList],
            });
            rerenderCalendar(dispatch, state, res, "add");
            // dispatch.calendarModel.getAllEvents();
            dispatch.toastModel.showToast({
              value: "Success",
              message: "Reminder has been added successfully",
            });
          }
        })
        .catch((err) => {
          return err.response;
        });
    },

    async editReminder(payload) {
      await axiosClient
        .post("/home/editReminder", payload)
        .then((res) => {
          if (res.status === 200) {
            this.setData({
              name: "reminderEditData",
              data: { ...res.data.data },
            });
          }
        })
        .catch((err) => {
          return err.response;
        });
    },

    async updateReminder(payload, state) {
      await axiosClient
        .post("/home/updateReminder", payload)
        .then((res) => {
          if (res.status === 200) {
            this.setData({
              name: "reminderEditData",
              data: { ...res.data.data },
            });
            dispatch.toastModel.showToast({
              value: "Success",
              message: "Reminder has been updated successfully",
            });
            rerenderCalendar(dispatch, state, res, "update");
            // dispatch.calendarModel.getAllEvents();
          }
        })
        .catch((err) => {
          return err.response;
        });
    },

    async deleteReminder(payload, state) {
      await axiosClient
        .post("/home/deleteReminder", payload)
        .then((res) => {
          if (res.status === 200) {
            const updatedannouncementList =
              state.homeModel.announcementMessage.map((announcement) => {
                if (announcement._id == res.data.data.messageId) {
                  return { ...announcement, isReminder: false };
                }
                return announcement;
              });
            this.setData({
              name: "announcementMessage",
              data: [...updatedannouncementList],
            });
            dispatch.toastModel.showToast({
              value: "Success",
              message: "Reminder has been deleted successfully",
            });
            rerenderCalendar(dispatch, state, res, "delete");
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    async projectAssignedList() {
      await axiosClient
        .get("/home/projectAssignedList")
        .then((res) => {
          if (res.status === 200) {
            this.setData({
              name: "assignProjectList",
              data: [...res.data.data.assignProjectList],
            });
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    async getMenteeContentURL(payload) {
      await axiosClient
        .post("/content/getContentURL", payload)
        .then((res) => {
          if (res.data.code === 200) {
            const { redirectURL } = res.data.data;
            let redirectURLWin = window.open(redirectURL, "_blank");
            dispatch.preMatchModel.setData({
              name: "redirectURLWin",
              data: redirectURLWin,
            });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
    async acceptCookies(payload) {
      return await axiosClient
        .post("/auth/acceptCookies", payload)
        .then((res) => {
          console.log(res);
        });
    },
  }),
};

export default homeModel;
