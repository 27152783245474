import SentEmailPopup from "../../components/Popup/SentEmailPopup";
import axiosClient from "../../api/axiosClient";
import {
  cookieDecryption,
  cookieEncryption,
  onBoardingHandler,
  handleLogOut,
} from "../../utils";
import PasswordResetSuccess from "../../components/Popup/PasswordResetSuccess";

const authModel = {
  state: {
    logInUser: {},
    isCookiePopup: true,
  },
  reducers: {
    setUserDetails(state, payload) {
      return {
        ...state,
        [payload.name]: payload.data,
      };
    },
  },
  effects: (dispatch) => ({
    async userLogin({ payload, handleNavigate }) {
      return await axiosClient
        .post("/auth/login", payload)
        .then(async (res) => {
          if (res.data.code === 200) {
            cookieEncryption("userDetails", res.data.data);
            localStorage.setItem("userStatus", "logged In");
            this.setUserDetails({
              name: "logInUser",
              data: {
                ...res.data.data,
                _id: res.data.data._id ?? res.data.data.userId,
                userId: res.data.data._id ?? res.data.data.userId,
              },
            });
            handleNavigate(onBoardingHandler(res.data.data.onboardingStep));
          } else {
            if (res.data.code !== 404 && res.data.code !== 400) {
              dispatch.toastModel.showToast({
                value: "Danger",
                message: res.data.msg,
              });
            }
            return { error: true, state: res.data.code, message: res.data.msg };
          }
        })
        .catch((err) => {
          return {
            error: true,
            state: 403,
            message: err?.response?.data.msg,
          };
        });
    },
    async userGhostLogin({ payload, handleNavigate }) {
      await axiosClient
        .post(`/auth/loginViaToken?token=${payload?.token}`)
        .then(async (res) => {
          if (res.data.code === 200) {
            if (res.data?.data?.pairImported) {
              handleNavigate(`/register/?id=${res.data?.data?.userId}`);

              dispatch.toastModel.showToast({
                value: "Danger",
                message: res.data.msg,
              });
              dispatch.authModel.getUserDetails({});
            } else {
              cookieEncryption("userDetails", res.data.data);
              localStorage.setItem("userStatus", "login via token");
              this.setUserDetails({
                name: "logInUser",
                data: {
                  ...res.data.data,
                  _id: res.data.data._id ?? res.data.data.userId,
                  userId: res.data.data._id ?? res.data.data.userId,
                },
              });
              handleNavigate(onBoardingHandler(res.data.data.onboardingStep));
            }
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
    async getEmail({ payload, setValue, setRole, handleNavigate }) {
      return await axiosClient
        .post("/auth/getUserEmail", payload)
        .then((res) => {
          if (res.data.code === 200) {
            setValue("email", res.data.data.email);
            setRole(res.data.data.role);
          } else {
            handleNavigate(`/?error=${res.data.msg}`);
            // dispatch.toastModel.showToast({
            //   value: "Danger",
            //   message: res.data.msg,
            // });
          }
        })
        .catch((err) => {
          handleNavigate("/");
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
    async userRegistration({ payload, handleNavigate, reset }) {
      return await axiosClient
        .post("/auth/register", payload)
        .then((res) => {
          if (res.data.code === 200) {
            reset();
            if (res.data?.data?.userObj?.pairImported) {
              handleNavigate("/application-complete");
              cookieEncryption("userDetails", {
                ...res.data.data.userObj,
                token: res?.data?.data?.token,
              });
              localStorage.setItem("userStatus", "logged In");
              this.setUserDetails({
                name: "logInUser",
                data: {
                  ...res.data.data,
                  _id: res.data.data.userObj._id ?? res.data.data.userObj._id,
                  userId:
                    res.data.data.userObj._id ?? res.data.data.userObj._id,
                  preferredFname:
                    res.data.data.userObj.preferredFname ??
                    res.data.data.userObj.preferredFname,
                  preferredLname:
                    res.data.data.userObj.preferredLname ??
                    res.data.data.userObj.preferredLname,
                  role:
                    res.data.data.userObj.role ?? res.data.data.userObj.role,
                    profilePic:res.data.data.userObj.profilePic
                },
              });
              dispatch.toastModel.showToast({
                value: "Success",
                message: res.data.msg,
              });
            } else {
              // cookieEncryption("userDetails", res.data.data);
              let auth2 = {
                ...res.data.data.userObj,
                token: res?.data?.data?.token,
              };
              const requiredAuth = {
                email : res.data.data.userObj.email,
                legalFname : res.data.data.userObj.legalFname,
                legalLname : res.data.data.userObj.legalLname,
                preferredFname : res.data.data.userObj.preferredFname,
                preferredLname : res.data.data.userObj.preferredLname,
                onboardingStep : res.data.data.userObj.onboardingStep,
                partnerAdmin : res.data.data.userObj.partnerAdmin,
                primaryPhoneNo : res.data.data.userObj.primaryPhoneNo,
                profilePic : res.data.data.userObj.profilePic,
                profilePicKey : res.data.data.userObj.profilePicKey,
                role : res.data.data.userObj.role,
                timezone : res.data.data.userObj.timezone,
                token : res.data.data.token,
                userId : res.data.data.userObj.userId,
              };
              cookieEncryption("userDetails", requiredAuth);
              localStorage.setItem("userStatus", "logged In");
              this.setUserDetails({
                name: "logInUser",
                data: {
                  ...res.data.data,
                  _id: res.data.data.userObj?._id ?? res.data.data.userObj._id,
                  userId:
                    res.data.data.userObj._id ?? res.data.data.userObj._id,
                },
              });
              handleNavigate(
                onBoardingHandler(res.data.data.userObj.onboardingStep)
              );
            }
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
            handleNavigate("/");
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.msg,
          // });
          handleNavigate("/");
        });
    },
    async requestResetPassword({ payload, reset }) {
      return await axiosClient
        .post("/auth/requestResetPassword", payload)
        .then((res) => {
          if (res.data.code === 200) {
            reset();
            dispatch.dialogModel.showDialog({
              component: () => <SentEmailPopup />,
              className: "forgot_popup_height",
            });
          } else {
            if (res.data.code !== 404 && res.data.code !== 400) {
              dispatch.toastModel.showToast({
                value: "Danger",
                message: res.data.msg,
              });
            }
            return { error: true, state: res.data.code };
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
    async changePassword({ payload, onContinue }) {
      return await axiosClient
        .post("/auth/forgotPassword", payload)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch.dialogModel.showDialog({
              component: () => <PasswordResetSuccess onContinue={onContinue} />,
              className: "password_popup_height",
            });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.msg,
          // });
        });
    },
    async getUserDetails({}) {
      return await axiosClient
        .get("auth/getUserDetail")
        .then((res) => {
          if (res.data.code === 200) {
            const auth = cookieDecryption("userDetails");
            // res.data.data.userObj["dob"] =
            //   res.data.data.userObj["dob"] ?? new Date().toUTCString();
            const { userObj } = res.data.data;
            const updatedAuth = {
              ...auth,
              fullName: userObj.preferredFname + " " + userObj.preferredLname,
              ...userObj,
            };
            cookieEncryption("userDetails", {
              ...auth,
              fullName: userObj.preferredFname + " " + userObj.preferredLname,
              ...userObj,
            });
            this.setUserDetails({
              name: "logInUser",
              data: {
                ...updatedAuth,
                _id: res.data.data.userObj._id
                  ? res.data.data.userObj._id
                  : res.data.data.userObj.userId,
                userId: res.data.data.userObj._id
                  ? res.data.data.userObj._id
                  : res.data.data.userObj.userId,
              },
            });
            dispatch.onBoardingModel.setData({
              name: "onboardingSteps",
              data: res.data.data,
            });
          } else {
            // dispatch.toastModel.showToast({
            //   value: "Danger",
            //   message: res.data.msg,
            // });
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
    async userCreateInquiry({ inquiryData, handleNavigate, setisDisable }) {
      return axiosClient
        .post("auth/createInquiry", inquiryData)
        .then((res) => {
          setisDisable(false);
          if (res.data.code === 200) {
            dispatch.toastModel.showToast({
              value: "Success",
              message: res.data.msg,
            });
            handleNavigate("/thankyou");
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          return err;
        });
    },
    async changePasswordUser({ payload, handleSettingOpen }) {
      return await axiosClient
        .post("auth/changePassword", payload)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch.toastModel.showToast({
              value: "Success",
              message: res.data.msg,
            });
            handleSettingOpen();
          } else {
            if (res.data.code !== 400) {
              dispatch.toastModel.showToast({
                value: "Danger",
                message: res.data.msg,
              });
            } else {
              return {
                error: true,
                state: res.data.code,
                message: res.data.msg,
              };
            }
          }
        })
        .catch((err) => {
          return err;
        });
    },
    async userProfileUpdate(payload) {
      return axiosClient
        .post("auth/userProfileUpdate", payload)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch.authModel.getUserDetails({});
            dispatch.toastModel.showToast({
              value: "Success",
              message: res.data.msg,
            });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          return err;
        });
    },
    async updateMessageNotificationStatus(payload) {
      return axiosClient
        .post("auth/updateMessageNotificationStatus", payload)
        .then((res) => {
          // if (res.data.code === 200) {
          // } else {
          //   dispatch.toastModel.showToast({
          //     value: "Danger",
          //     message: res.data.msg,
          //   });
          // }
        })
        .catch((err) => {
          return err;
        });
    },
  }),
};

export default authModel;
