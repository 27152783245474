import { imentor_LOGO, Mentor_home1 } from "../../../utils/images";

const InfoImage = () => {
  return (
    <div className="login_info_img">
      <div className="logo">
        <img src={imentor_LOGO} alt="imentor-logo" />
      </div>
      <h3 className="champion_title">Every Student Deserves a Champion</h3>
      <p className="champion_desc">
        Through mentoring, we help young people achieve success in college and
        career.
      </p>
      <span>
        <img src={Mentor_home1} alt="" />
      </span>
    </div>
  );
};

export default InfoImage;
