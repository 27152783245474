import axiosClient from "../../api/axiosClient";

const calendarModel = {
  state: {
    allEvents: [],
    dateSpecifiedEvents: [],
    selectedDate:"",
  },
  reducers: {
    setData(state, payload) {
      return { ...state, [payload.name]: payload.data };
    },
  },
  effects: (dispatch) => ({
    async getAllEvents() {
      await axiosClient
        .get("/home/getCalenderEventList")
        .then((res) => {
          if (res.data.code == 200) {
            this.setData({
              name: "allEvents",
              data: [...res.data.data.getCalendarList],
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async getDateWiseCalenderEventList({ payload }) {
      await axiosClient
        .post("/home/getDateWiseCalenderEventList", payload)
        .then((res) => {
          if (res.data.code == 200) {
            this.setData({
              name: "dateSpecifiedEvents",
              data: [...res.data.data.getCalendarList],
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
  }),
};
export default calendarModel;
