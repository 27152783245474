import Loader from "../layout/Loader";
import AuthLayout from "../layout/AuthLayout";
import { cookieDecryption, handleLogOut, removeCookie } from "../utils";
import { memo, Suspense, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";

const PublicRouter = ({ title, layout }) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const id = params.get("id");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!!token) {
      handleLogOut();
      navigate(`/?token=${token}`);
    }
  }, [token]);
  useEffect(() => {
    if (!!id && window.location?.pathname.includes("register")) {
      handleLogOut();
      navigate(`/register/?id=${id}`);
    }
  }, [id]);

  let redirectPath = "/home";

  // switch (auth?.role) {
  //   case "Super Admin":
  //     redirectPath = "/admin-users";
  //     break;
  //   case "Partner Super Admin":
  //     redirectPath = "/admin-users";
  //     break;

  //   default:
  //     redirectPath = "/partners";
  //     break;
  // }
  const auth = cookieDecryption("userDetails");
  const getLayout = (layout) => {
    switch (layout) {
      case "AUTH_LAYOUT": {
        return (
          <AuthLayout>
            <Outlet context={["sohel"]} />
          </AuthLayout>
        );
      }
      default: {
        return <Outlet context={["sohel"]} />;
      }
    }
  };

  return !auth ? (
    <Suspense fallback={<Loader />}>
      <HelmetProvider context={{}}>
        <title>{title}</title>
        {getLayout(layout)}
      </HelmetProvider>
    </Suspense>
  ) : (
    <Navigate to={redirectPath} state={{ from: location }} replace />
  );
};

export default memo(PublicRouter);
