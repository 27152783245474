import { routePermission } from '../../utils';
import store from '../../rematch';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

const ResizeLayout = ({ children }) => {
    const { dispatch } = store;
    const receiverId = useParams();

    useEffect(() => {
        window.addEventListener("resize", function (event) {
            if (routePermission?.some(path => window.location.pathname.includes(path)) && receiverId && window.innerWidth < 580) {
                dispatch.homeModel.setData({ name: "resizeWidth", data: true })
            } else {
                dispatch.homeModel.setData({ name: "resizeWidth", data: false })
            }
        })
    }, [])

    return children
}

export default ResizeLayout
