import ErrorFallback from "./ErrorFallback";
import RoutesComponent from "./routes/RoutesComponent";
import Toast from "./components/Toast";
import CustomModal from "./components/CustomModal";
import store from "./rematch";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/_global.scss";
import { useEffect, useState } from "react";
import { cookieDecryption, getAccessToken } from "./utils";
import { useParams } from "react-router-dom";
import TagManager from "react-gtm-module";
import CookiePopup from "./components/Popup/CookiePopup";

const App = () => {
  const auth = cookieDecryption("userDetails");
 
  window.addEventListener('storage', function(e) {
    if (e.key === 'userStatus') {
        window.location.reload()
    }
});
  const userData = useSelector((state) => state.authModel.logInUser);
  const socket = useSelector((state) => state?.socketModel?.socket);
  const socketConnected = useSelector(
    (state) => state?.socketModel?.socketConnected
  );
  const toaster = useSelector((state) => state.toastModel);
  const dialogModel = useSelector((state) => state.dialogModel);
  const { open, component: Component, className } = dialogModel;
  const { dispatch } = store;
  const receiverId = useParams();

  useEffect(() => {
    if (Object.keys(userData || {}).length && !socketConnected) {
      dispatch.socketModel.getSocket({ token: userData?.token, userData });
    }
  }, [userData]);

  useEffect(() => {
    if (Object.keys(socket || {}).length) {
      socket.on("response", (response) => {
        dispatch.socketModel.socketResponse({ response });
      });
    }
    return () => {
      if (Object.keys(socket || {}).length) {
        socket.off("response", function (err) {
          console.error("response off", err);
        });
      }
    };
  }, [socket]);
  const tagManagerArgs = {
    gtmId: "GTM-PFF836CC",
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);
  useEffect(() => {
    if (auth) {
      const tagManagerArgs = {
        gtmId: "GTM-PFF836CC",
        dataLayer: {
          user_type: auth?.role?.toLowerCase(),
          user_org:
            auth?.partnerAdmin?.partnerName || auth?.region?.region || "",
          user_school:
            auth?.assignedSchoolOrInstitutions?.join(",").toLowerCase() || "",
        },
      };
      // TagManager.initialize(tagManagerArgs);
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [auth]);

  useEffect(() => {
    if (auth) {
      dispatch.authModel.getUserDetails({});
    }
  }, []);

  const onClose = () => {
    dispatch.dialogModel.hideDialog({});
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <RoutesComponent />
      <Toast toastList={toaster.list} autoDelete={true} autoDeleteTime={3000} />
      <CustomModal className={className} onClose={onClose} open={open}>
        <Component />
      </CustomModal>
      <CookiePopup />
    </ErrorBoundary>
  );
};

export default App;
