import { init } from "@rematch/core";
import createLoadingPlugin from "@rematch/loading";
import authModel from "./model/auth.model";
import eventModel from "./model/event.model";
import menteesModel from "./model/mentees.model";
import profileModel from "./model/profile.model";
import preMatchModel from "./model/preMatch.model";
import toastModel from "./model/toast.model";
import dialogModel from "./model/Dialog.model";
import onBoardingModel from "./model/onBoarding.model";
import calendarModel from "./model/calendar.model";
import progressConnectionModel from "./model/progressConnection.model";
import messagesModel from "./model/messages.model";
import homeModel from "./model/home.model";
import socketModel from "./model/socket.model";
import contentProgressModel from "./model/contentProgress.model"
import mentorModel from "./model/mentor.model"
const loadingPlugin = createLoadingPlugin();

const store = init({
  redux: {
    rootReducers: {
      RESET: (state, action) => {},
    },
  },
  plugins: [loadingPlugin],
  models: {
    authModel,
    eventModel,
    homeModel,
    menteesModel,
    profileModel,
    preMatchModel,
    toastModel,
    dialogModel,
    onBoardingModel,
    calendarModel,
    progressConnectionModel,
    messagesModel,
    socketModel,
    contentProgressModel,
    mentorModel
  },
});

export default store;
