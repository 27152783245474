import { memo } from "react";
import { BrowserRouter as Router, Routes, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import UnAuthorized from "../pages/Unauthorized";
import { privateRouteList, publicRouteList, registerRouteList } from ".";
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import RegisterRouter from "./RegisterRouter";

function RoutesComponent() {

  const render = () => {
    return (
        <>
           {/* Private Route List */}
        {privateRouteList.map(
          ({ title, path, isBack, allowedRoles, className ="", layout, element: Element }) => (
            <Route
              element={
                <PrivateRouter
                  layout={layout}
                  allowedRoles={allowedRoles}
                  title={title}
                  isBack={isBack}
                  className={className}
                />
              }
            >
              <Route path={path} element={<Element />} />
            </Route>
          )
        )}

        {/* Register Route List */}
        {registerRouteList.map(
          ({ title, path, allowedRoles, layout, element: Element }) => (
            <Route
              element={
                <RegisterRouter
                  layout={layout}
                  allowedRoles={allowedRoles}
                  title={title}
                />
              }
            >
              <Route path={path} element={<Element />} />
            </Route>
          )
        )}

        {/* Public Route List  */}
        {publicRouteList.map(({ title, path, layout, element: Element }) => (
          <Route element={<PublicRouter layout={layout} title={title} />}>
            <Route path={path} element={<Element />} />
          </Route>
        ))}

        <Route path={"/*"} element={<UnAuthorized />} />
        </>
    )
}

const router = createBrowserRouter(createRoutesFromElements(render()));

return <RouterProvider router={router} />
}

export default memo(RoutesComponent);
