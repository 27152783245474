import axiosClient from '../../api/axiosClient';


const profileModel = {
  state: {
    menteesProfileId:"",
    isMenteesProfile:false,
    menteesDetails:{},
    isDisable:false,
    userDetails:{},
  },
  reducers: {
    setData(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async getMenteesProfile({payload,navigate}) {

      await axiosClient
        .post("/mentor/getMenteeUserDetail", payload)
        .then((res) => {
          if (res.status === 200) {
            const  menteeObj  = res.data.data;
            this.setData({
              menteesDetails: menteeObj,
              isMenteesProfile: true,
              userDetails:menteeObj?.menteeObj
            });
          }
        })
        .catch((err) => {
          if(err.response.status === 400){

            navigate("/unauthorized")
          }

          return err.response;
        });
    },
    async getMentorsProfile({payload,navigate}) {
      await axiosClient
        .post("/mentee/getMentorUserDetail", payload)
        .then((res) => {
          if (res.status === 200) {
            const  menteeObj  = res.data.data;
            this.setData({
              menteesDetails: menteeObj,
              isMenteesProfile: true,
              userDetails:menteeObj?.mentorObj
            });
          }
        })
        .catch((err) => {
          if(err.response.status === 400){

            navigate("/unauthorized")
          }

          return err.response;
        });
    },
  }),
};

export default profileModel;
