import React from "react";
import { imentor_LOGO } from "../../utils/images";
import InfoImage from "./InfoImage";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../layout/Loader";

const AuthLayout = ({ children }) => {
  const pathname = window.location.pathname;
  const ghostLoginLoader = useSelector(
    (state) => state.loading.effects.authModel.userGhostLogin
  );
  if (ghostLoginLoader) {
    return (
      <Loader />
      // <div className="d_flex_center h-screen w-screen">
      //   <Loader className="animate-spin" />
      // </div>
    );
  }
  return (
    <section
      className={`login_form sign_in_form forgot_pass_page change_pass ${
        window.location.pathname.includes("/inquiry-form/")
          ? "mentor_inquiry_form"
          : ""
      }`}
    >
      <div className="container">
        <div className="mobile_logo d_none">
          <img src={imentor_LOGO} alt="imentor-logo" />
        </div>
        <div className="login_info">
          <div className="login_details">{children}</div>
          <InfoImage />
        </div>
      </div>
    </section>
  );
};

export default AuthLayout;
