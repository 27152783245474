import React from "react";

function PublicLayout({ children }) {
  return (
    <div className="wrapper flex">
      <div className={""}>{children}</div>
    </div>
  );
}

export default PublicLayout;
