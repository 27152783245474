import {
  UTCToFormattedPartnerTimeZone,
  UTCToPartnerTimeZone,
  cookieDecryption,
  generateCustomAvatar,
  getDurationDate,
} from "../../../utils";
import { home_noti_reminder_icon } from "../../../utils/images";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import store from "../../../rematch";
import { Loader } from "lucide-react";
import { useNavigate } from "react-router-dom";

const NotificationPopup = ({ popoverRef }) => {
  const state = useSelector((state) => state.eventModel);
  const socketConnected = useSelector(
    (state) => state.socketModel.socketConnected
  );
  const { dispatch } = store;
  const [pagination, setPagination] = useState({ page: 1 });
  const { getNotificationList } = state;
  const loading = useSelector(
    (state) => state.socketModel.socketLoaders.getNotifications
  );
  const navigate = useNavigate();
  const [rowList, setRowList] = useState([]);

  const userDetails = cookieDecryption("userDetails");
  useEffect(() => {
    if (getNotificationList?.data?.length > 0) {
      let temp = [ ...getNotificationList?.data,...rowList];
      const unique2 = temp.filter((obj, index) => {
        return index === temp.findIndex((o) => obj._id === o._id);
      });
      // setRowList([...getNotificationList?.data]);
      setRowList(unique2);
    }
  }, [getNotificationList]);

  useEffect(() => {
    if (socketConnected) {
      const payload = {
        event: "getNotifications",
        data: { page: 1, limit: 10 },
      };
      dispatch.socketModel.sendEvent({ payload });
    }
    return () => {
      setPagination({ page: 1 });
      dispatch.eventModel.setData({ getNotificationList: {} });
      setRowList([]);
    };
  }, []);

  const handleMarkRead = () => {
    const payload = {
      event: "readNotification",
      data: { type: "all" },
    };
    dispatch.socketModel.sendEvent({ payload });
    setRowList(rowList.map((e) => ({ ...e, read: true })));
  };
  const emojis = {
    Heart: "❤️",
    Smile: "😂",
    Sad: "😞",
    DisBelief: "😅",
    ThumsUp: "👍",
  };
  const matchedFound = (dataId) => {
    const payload = {
      event: "matchedFound",
      data: { dataId },
    };
    dispatch.socketModel.sendEvent({ payload });
  };
  const handleClick = (type, id, notiId, pairPromptChatId) => {
    const payload = {
      event: "readNotification",
      data: { notiId },
    };
    dispatch.socketModel.sendEvent({ payload });
    popoverRef.current.click();
    switch (type) {
      case "Matched":
        if (userDetails?.role === "Mentor") {
          navigate("/mentees");
        } else {
          navigate("/mentors");
        }
        break;
      case "eventApproved":
      case "eventNotApproved":
      case "InvitationApproved":
      case "InvitationDeclined":
      case "eventDecline":
        navigate("/events");
        break;
      case "eventInvited":
        navigate("/events", { state: { currentTab: 1 } });
        break;
      case "Message":
      case "Reaction":
      case "Pair Prompt":
        if (window.location.pathname === `/messages/${id}`) {
          break;
        }
        if (window.location.pathname === `/messages/${pairPromptChatId}`) {
          break;
        }
        if (type === "Pair Prompt") {
          navigate(`/messages/${pairPromptChatId}`);
        } else {
          navigate(`/messages/${id}`);
        }
        const payload = {
          event: "getMsgList",
          data: { search: "" },
        };

        dispatch.socketModel.sendEvent({ payload });
        break;
      case "AssignProject":
      case "AssignTraining":
        navigate(`/content`);
        break;
      case "Announcement":
      case "Reminder":
        navigate(`/home`);
        break;
      default:
        break;
    }
  };
  const contentDataFormate = (data) => {
    switch (data?.type) {
      case "Reminder": {
        return [
          <span className="bold_txt">iMentor Reminder</span>,
          <br/>,
          <span >{data?.reminder?.title}</span>,
        ]?.map((e) => e);
      }

      case "Announcement": {
        let temp = data?.content.split("Announcement");
        const announcementIndex = data?.content?.indexOf("Announcement:");
        let realMesg = ''
        if (announcementIndex !== -1) {
           realMesg = data?.content
          .substring(announcementIndex + "Announcement:".length)
          .trim();
        }
        return [
          <span className="bold_txt">{temp[0]}</span>,
          <br />,
          "Announcement: ",
          <span>
            {realMesg.substring(0, 75)}
            {realMesg?.length > 75 && "..."}
          </span>,
        ]?.map((e) => e);
        // let temp = 
      }

      case "eventApproved": {
        return [
          <span className="bold_txt">
            {data?.users?.preferredFname} {data?.users?.preferredLname}
          </span>,
          <br />,
          <span>
            {data?.content} {data?.event?.event_name}
          </span>,
        ]?.map((e) => e);
      }
      case "InvitationApproved": {
        return [
          <span className="bold_txt">
            {data?.users?.preferredFname} {data?.users?.preferredLname}
          </span>,
          <br />,
          <span>{data?.content}</span>,
        ]?.map((e) => e);
      }

      case "eventNotApproved": {
        let temp = data?.content.split(" rejected the event request.");
        return [
          <span>{temp[0]}</span>,
          <span className="event_approved event_reject cursor-pointer">
            {" "}
            rejected the event request.
          </span>,
        ]?.map((e) => e);
      }
      case "InvitationDeclined": {
        let temp = data?.content.split("has declined the event");
        return [
          <span className="bold_txt">
            {data?.users?.preferredFname} {data?.users?.preferredLname}
          </span>,
          <br />,
          <span>{data?.content}</span>,
          <span>{temp[1]}</span>,
        ]?.map((e) => e);
      }

      case "eventDecline": {
        return [
          <span className="bold_txt">
            {data?.users?.preferredFname} {data?.users?.preferredLname}
          </span>,
          <br />,
          <span>
            {data?.content} {data?.event?.event_name}
          </span>,
        ]?.map((e) => e);
      }

      case "eventInvited": {
        let temp = data?.content.split("Please RSVP:");
        return [
          <span className="bold_txt">
            {(data?.users?.preferredFname || data?.users?.legalFname) +
              " " +
              (data?.users?.preferredLname || data?.users?.legalLname)}
          </span>,
          <br />,
          <span>Please RSVP: {data?.event?.event_name}</span>,
          <span className="bold_txt">{temp[1]}</span>,
        ]?.map((e) => e);
      }

      case "AssignProject":
      case "AssignTraining": {
        let temp = data?.content.split("assigned you a");
        return [
          <span className="bold_txt">
            {data?.users?.preferredFname} {data?.users?.preferredLname}
          </span>,
          <br />,
          <span>{data?.content}</span>,
        ]?.map((e) => e);
      }

      case "Message": {
        return [
          <span className="bold_txt">
            {data?.users?.preferredFname} {data?.users?.preferredLname}
          </span>,
          <span>{data?.content}</span>,
          <br />,
          <span>
            {data?.message?.msg?.substring(0, 75)}
            {data?.message?.msg?.length > 75 && "..."}
          </span>,
        ]?.map((e) => e);
      }

      case "Reaction": {
        let temp = data?.content.split("reacted on your");
        return [
          <span className="bold_txt">
            {data?.users?.preferredFname} {data?.users?.preferredLname}
          </span>,
          <span>
            {" "}
            reacted{" "}
            {emojis?.[data?.message?.reactions?.[0]] && (
              <span>{emojis?.[data?.message?.reactions?.[0]]}</span>
            )}{" "}
            {`on your ${temp[1]}`}
          </span>,
          <br />,
          <span>
            {`${data?.message?.msg.substring(0, 75)}`}
            {data?.message?.msg?.length > 75 && "..."}
          </span>,
        ]?.map((e) => e);
      }

      case "CourseCompleted": {
        return [
          <span className="bold_txt">
            {data?.users?.preferredFname} {data?.users?.preferredLname}
          </span>,
          <br />,
          <span> {data?.content}</span>,
        ]?.map((e) => e);
      }

      case "Matched": {
        let temp = data?.content.split("matched. Click here");
        return [
          <span className="bold_txt">
            {data?.users?.preferredFname} {data?.users?.preferredLname}
          </span>,
          <br />,
          <span>{temp[0]}</span>,
        ]?.map((e) => e);
      }
      case "Pair Prompt": {
        return [
          <span className="bold_txt">
            {data?.users?.preferredFname} {data?.users?.preferredLname}
          </span>,
          <span> Shared with</span>,
          <span className="bold_txt">
            {" "}
            {data?.pairinfos?.mentor?.preferredFname}{" "}
            {data?.pairinfos?.mentor?.preferredLname}
          </span>,
          <span> or</span>,
          <span className="bold_txt">
            {" "}
            {data?.pairinfos?.mentee?.preferredFname}{" "}
            {data?.pairinfos?.mentee?.preferredLname}
          </span>,
          <br />,
          <span>
            {" "}
            {data?.pairPrompt?.message?.substring(0, 75)}
            {data?.pairPrompt?.message?.length > 75 && "..."}
          </span>,
        ]?.map((e) => e);
      }
      default:
        return data?.content;
    }
  };

  const handleScrollElement = (e) => {
    if (
      e?.target?.scrollHeight ===
      Math.ceil(e?.target?.scrollTop) + e?.target?.clientHeight
    ) {
      if (getNotificationList?.totalPage > pagination?.page) {
        setPagination({ ...pagination, page: Number(pagination?.page) + 1 });
        const payload = {
          event: "getNotifications",
          data: { page: Number(pagination?.page) + 1, limit: 10 },
        };
        dispatch.socketModel.sendEvent({ payload });
      }
    }
  };

  const handleEventInvited = (e, isStatus) => {
    dispatch.eventModel.eventGuestApproval({
      eventId: e?.dataId,
      isApproved: isStatus,
    });
    setRowList(rowList?.filter((ele) => ele?._id !== e?._id));
  };

  const imageChange = (e) => {
    switch (e?.type) {
      case "Reminder":
        return (
          <div className="reminder_noti_icon">
            <img
              src={home_noti_reminder_icon}
              alt="Reminder"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = process.env.REACT_APP_DEFAULT_THUMBNAIL_URL;
              }}
            />
          </div>
        );
      case "eventInvited":
      case "AssignProject":
      case "Announcement":
      case "AssignTraining":
        return (
          <div className="notification_small_img">
            <img
              src={e?.users?.profilePic}
              alt="Project"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = process.env.REACT_APP_DEFAULT_THUMBNAIL_URL;
              }}
            />
          </div>
        );

      case "eventDecline" || "eventNotApproved" || "eventApproved":
        return (
          <div className="notification_small_img">
            <img
              src={e?.users?.profilePic}
              alt="Reminder"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = process.env.REACT_APP_DEFAULT_THUMBNAIL_URL;
              }}
            />
          </div>
        );

      default:
        return (
          <img
            src={
              e?.users?.profilePic
                ? e?.users?.profilePic
                : generateCustomAvatar(
                    `${e?.users?.preferredFname || e?.users?.legalFname}`,
                    `${e?.users?.preferredLname || e?.users?.legalLname}`
                  )
            }
            alt="notification"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = process.env.REACT_APP_DEFAULT_THUMBNAIL_URL;
            }}
          />
        );
    }
  };

  return (
    <>
      <div className="notification_header">
        <div className="notification_txt_link">
          <h6>Notifications</h6>
          {rowList?.length > 0 && (
            <a className="cursor_pointer" onClick={() => handleMarkRead()}>
              Mark all as read
            </a>
          )}
        </div>
        <div
          className="notification_scroll custom-scroll"
          onScroll={(e) => (loading ? "" : handleScrollElement(e))}
        >
          {rowList?.length > 0 ? (
            rowList?.map((e) => {
              let x = moment();
              let y = moment(moment(e?.createdAt));
              let duration = moment.duration(x.diff(y));
              let x2 = UTCToPartnerTimeZone(
                new Date()?.toISOString(),
                userDetails?.timezone
              );
              let y2 = UTCToPartnerTimeZone(
                e?.createdAt,
                userDetails?.timezone
              );
              let duration2 = moment.duration(x2.diff(y2));

              if (!e.content) {
                return;
              }
              let pairPromptChatId =
                e.type === "Pair Prompt"
                  ? userDetails?.role === "Mentor"
                    ? e?.pairinfos?.menteeId
                    : e?.pairinfos?.mentorId
                  : "";
              return (
                <div
                  className={`notification_list_box cursor-pointer ${
                    !e?.read ? "" : "bg_remove"
                  }`}
                  onClick={() =>
                    handleClick(
                      e?.type,
                      e?.users?._id,
                      e?._id,
                      pairPromptChatId
                    )
                  }
                >
                  <div className="notification_small_img">{imageChange(e)}</div>
                  <div className="admin_post_list">
                    <p>{contentDataFormate(e)}</p>
                    <p className="min-w-[275px]">
                      {getDurationDate(
                        duration2,
                        UTCToFormattedPartnerTimeZone(
                          e?.createdAt,
                          userDetails?.timezone
                        )
                      )}
                      <span className="noti_dot"></span>
                      {UTCToPartnerTimeZone(
                        e?.createdAt,
                        userDetails?.timezone
                      ).format("MMM DD, yyyy")}
                      {` (${userDetails?.timezone?.abbr})`}
                    </p>

                    {e?.type === "eventInvited" && (
                      <>
                        <div className="festival_details">
                          <p className="festival_time">
                            {UTCToPartnerTimeZone(
                              e?.event?.start_date,
                              userDetails?.timezone
                            ).format("ddd, MMM DD, HH:mm")}
                          </p>
                          <div className="festival_img">
                            <img
                              src={
                                e?.event?.thumbnail
                                  ? e?.event?.thumbnail
                                  : process.env.REACT_APP_DEFAULT_THUMBNAIL_URL
                              }
                              className="w_100"
                              alt="festival"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                  process.env.REACT_APP_DEFAULT_THUMBNAIL_URL;
                              }}
                            />
                          </div>
                          <p className="city_txt">{e?.event?.event_name}</p>
                        </div>
                        {e.event?.status !== "Ended" && (
                          <div className="invite_btns">
                            {e?.event?.approve_status === "Pending" ? (
                              <>
                                <button
                                  className="btn_outline"
                                  onClick={() => handleEventInvited(e, false)}
                                >
                                  Decline
                                </button>
                                <button
                                  className="btn_primary btn"
                                  onClick={() => handleEventInvited(e, true)}
                                >
                                  Accept
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className={`btn danger_btn ${
                                    e?.event?.approve_status === "Declined"
                                      ? "exp"
                                      : "min"
                                  }`}
                                  onClick={() =>
                                    e?.event?.approve_status === "Declined"
                                      ? ""
                                      : handleEventInvited(e, false)
                                  }
                                >
                                  {e?.event?.approve_status === "Declined"
                                    ? "Declined"
                                    : "Decline"}
                                </button>
                                <button
                                  className={`btn btn_primary ${
                                    e?.event?.approve_status === "Approved"
                                      ? "exp"
                                      : "min"
                                  }`}
                                  onClick={() =>
                                    e?.event?.approve_status === "Approved"
                                      ? ""
                                      : handleEventInvited(e, true)
                                  }
                                >
                                  {e?.event?.approve_status === "Approved"
                                    ? "Accepted"
                                    : "Accept"}
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {!e?.read && (
                    <div className="blue_dot_noti">
                      <span></span>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="notification_list_box">
              <p>Notification not available</p>
            </div>
          )}

          {loading && (
            <div className="home_noti_loader">
              <Loader className="animate-spin mr-2" />
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
      {/* )} */}
    </>
  );
};

export default NotificationPopup;
