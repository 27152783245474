import React, { Suspense, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Loader from "../Loader";
import { Close, cookie, up_right_cookies } from "../../utils/images";
import { cookieDecryption, cookieEncryption } from "../../utils";
import { useSelector } from "react-redux";
import store from "../../rematch";

function PrivateLayout({ children, routeData }) {
  let userDetails = cookieDecryption("userDetails");
  const { isCookieAccepted } = useSelector((state) => state?.homeModel);
  const { dispatch } = store;
  useEffect(() => {
    if(userDetails?.isCookieAccepted){
      dispatch.homeModel.setData({name:"isCookieAccepted",data:true});
    }
  }, []);
  const handleCookie = (type) => {
    if (type === "Decline") {
      let temp = { ...userDetails, isCookieAccepted: true };
      dispatch.homeModel.setData({name:"isCookieAccepted",data:true});
      // temp.isCookieAccepted = true;
      cookieEncryption("userDetails", temp);
    } else if (type === "Accept"){
      let temp = { ...userDetails, isCookieAccepted: true };
      dispatch.homeModel.setData({name:"isCookieAccepted",data:true});
      cookieEncryption("userDetails", temp);

      dispatch?.homeModel?.acceptCookies({ isCookieAccepted: true });
    }
  };
  return (
    <div className="wrapper flex">
      <Sidebar />
      <div className={"main"}>
        <Header routeData={routeData} />
        <Suspense fallback={<Loader />}>{children}</Suspense>
      </div>
    </div>
  );
}

export default PrivateLayout;
