import { Button } from "../../../components/ui/button";
import { save_draft } from "../../../utils/images";
import AllSteps from "./AllSteps";

const StepHeader = ({ userObj, handleNavigate, onSave, loading }) => {
  return (
    <div className="basic_info_list z-50">
      <div className="d_flex">
        <div className="onboarding_title">
          <h5>Onboarding</h5>
        </div>
        <AllSteps handleNavigate={handleNavigate} userObj={userObj} />
        <div className="save_draft_btn">
          <Button
            className="save_title btn_outline cursor-pointer"
            loading={loading}
            onClick={() => onSave()}
          >
            {!loading && <img src={save_draft} alt="save-draft" />}
            <span>Save & Exit</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StepHeader;
