import {
  Close1,
  black_Teacher,
  black_student,
  Success_Checkmark,
} from "../../../utils/images";
import store from "../../../rematch";
import { generateCustomAvatar } from "../../../utils";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MatchedPopup = () => {
  const { matchedPopup, matchedData } = useSelector((state) => state.homeModel);
  const { menteeId, mentorId } = matchedData;
  const { dispatch } = store;
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.authModel?.logInUser);

  const handleClose = () => {
    dispatch.homeModel.setData({ name: "matchedPopup", data: !matchedPopup });
  };

  useEffect(() => {
    if (matchedPopup) {
      document.body.style["overflow-y"] = "hidden";
    }
    return () => {
      document.body.style["overflow-y"] = "auto";
    };
  }, [matchedPopup]);
  return (
    <>
      {matchedPopup && (
        <div className="i_popup i_match_into_pair">
          <div className="i_popup_content">
            <div className="i_popup_header d_flex_between popup_center_title">
              <h5 className="i_popup_title">You’ve Been Matched</h5>
              <div className="i_close">
                <img
                  src={Close1}
                  alt="close"
                  className="cursor_pointer"
                  onClick={() => handleClose()}
                />
              </div>
            </div>
            <div className="i_inner_content">
              <div className="matches_user">
                <h6>
                  {menteeId?.preferredFname + " " + menteeId?.preferredLname}
                </h6>
                <div className="profile_img">
                  <img
                    src={
                      menteeId?.profilePic ||
                      generateCustomAvatar(
                        menteeId?.preferredFname,
                        menteeId?.preferredLname
                      )
                    }
                    alt="profile_img mentee"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src =
                        process.env.REACT_APP_DEFAULT_THUMBNAIL_URL;
                    }}
                  />
                </div>
                <div className="tag_name">
                  <img src={black_student} alt="student" />
                  <p>MENTEE</p>
                </div>
              </div>
              <div className="s_checkmark">
                <img src={Success_Checkmark} alt="Success_Checkmark" />
              </div>
              <div className="matches_user">
                <h6>
                  {mentorId?.preferredFname + " " + mentorId?.preferredLname}
                </h6>
                <div className="profile_img">
                  <img
                    src={
                      mentorId?.profilePic ||
                      generateCustomAvatar(
                        mentorId?.preferredFname,
                        mentorId?.preferredLname
                      )
                    }
                    alt="profile_img mentorId"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src =
                        process.env.REACT_APP_DEFAULT_THUMBNAIL_URL;
                    }}
                  />
                </div>
                <div className="tag_name">
                  <img src={black_Teacher} alt="Teacher" />
                  <p>MENTOR</p>
                </div>
              </div>
            </div>
            <div className="imentor_info pb_32 border_bottom_1">
              <p>
                Get to know your match! Toggle to{" "}
                {role === "Mentor" ? "Mentees" : "Mentors"} on your home screen
                and click on their name to see what you have in common.
              </p>
            </div>
            <div className="i_popup_footer">
              <form className="d_flex justify_content_center">
                {/* <a onClick={()=>{window.top.location = "mailto:hello@imentor.org"}} href="mailto:hello@imentor.org" className="btn_outline">
                                    // <button className="btn_request_info btn_Cancel">
                                        Learn More
                                    // </button>

                                </a> */}
                <button
                  type="submit d_flex_center"
                  className="btn_approve btn"
                  onClick={() => {
                    handleClose();
                    navigate("/home");
                  }}
                >
                  OK
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MatchedPopup;
