import axios from "axios";
import Cookies from "js-cookie";
import { cookieDecryption, expireTime } from "../utils";
import SessionExpiredPopup from "../components/Popup/SessionExpiredPopup";
import store from "../rematch";

// baseURL: 'https://cmsapi.imentor.org/api/web/',
// console.log(process.env.REACT_APP_BASE_URL,"process.env.REACT_APP_BASE_URL")
// console.log(process.env.REACT_APP_SOCKET_API_KEY,"process.env.REACT_APP_SOCKET_API_KEY")
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
let isErrorOccurred = false;
axiosClient.defaults.timeout = 600000;
// axiosClient.interceptors.request.use(function (config) {
//   if (isErrorOccurred) {
//     return Promise.reject("An error occurred, stopping subsequent API calls");
//   }
//   return config;
// });
axiosClient.interceptors.response.use(
  function (response) {
    if (isErrorOccurred) {
      return Promise.reject({message:"Request failed with status code 401"});
    }
    if (response.data?.code === 401) {
      Cookies.remove("userDetails", {
        path: "/",
        expires: expireTime(),
      });
      isErrorOccurred = true;
      window.location.reload();
    }
    return response;
  },
  function (error) {
    if (isErrorOccurred) {
      return Promise.reject({message:"Request failed with status code 401"});
    }
    const { dispatch, getState } = store;
    let res = error.response;
    console.error("Looks like there was a problem. Status Code:" + res.status);
    if (res.status === 401 || res.status === 403) {
      const state = getState();
      isErrorOccurred = true;
      if (state.messagesModel.isErrorOccured) {
      } else {
        dispatch.messagesModel.setMsgState({
          name: "isErrorOccured",
          data: true,
        });
        dispatch.dialogModel.showDialog({
          component: () => <SessionExpiredPopup />,
          className: "alert_popup_height",
        });
      }
    }
    return Promise.reject(error);
  }
);

axiosClient.interceptors.request.use(function (config) {
  const auth = cookieDecryption("userDetails");
  const token = auth?.token;
  config.headers["x-auth-token"] = token || "";
  return config;
});

export default axiosClient;
