import {
  active_tab_icon,
  save_draft,
  Success_tab_Checkmark,
  tab_icon,
} from "../../../../../utils/images";

const StepBlock = ({ step, handleNavigate }) => {
  const getIcon = (completed) => {
    if (step.active) {
      if (!completed) {
        return <img className="blue_icon" src={active_tab_icon} alt="" />;
      } else {
        return <img className="success" src={Success_tab_Checkmark} alt="" />;
      }
    } else {
      if (!completed) {
        return <img className="blue_icon grayscale" src={active_tab_icon} alt="" />;
      } else {
        return <img className="success" src={Success_tab_Checkmark} alt="" />;
      }
    }
  };

  return (
    <li
      className={step.active ? "active" : ""}
      onClick={() => handleNavigate(step)}
    >
      <div className="basic_info">
        <div className="right_side_arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M9 6L15 12L9 18" stroke="#4A6980" stroke-width="1.5" />
          </svg>
        </div>
        <div className={`basic_details ${step.active ? "active" : ""}`}>
          {getIcon(step.completed)}
          <span>
            {step.id}. <span className="mobile_none">{step.name}</span>
          </span>
          {(step.id === 1 || step.id === 3) && <span className="star_error">*</span>}
        </div>
      </div>
    </li>
  );
};

export default StepBlock;
