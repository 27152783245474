import StepBlock from "./StepBlock";

const AllSteps = ({ userObj = {}, handleNavigate }) => {
  const { onboardingStep } = userObj;
  
  const steps = [
    {
      id: 1,
      name: "Basic Info",
      completed: onboardingStep > 1  ? true : false,
      active: window.location.pathname === "/basic-info"
    },
    {
      id: 2,
      name: "Additional Info",
      completed: onboardingStep > 2 ? true : false,
      active: window.location.pathname === "/additional-info"
    },
    {
      id: 3,
      name: "Matching Questions",
      completed: onboardingStep > 3 ? true : false,
      active: window.location.pathname === "/matching-questions"
    },
  ];

  return (
    <ul className="d_flex align_items_center">
      {steps.map((step) => {
        return <StepBlock handleNavigate={handleNavigate} step={step} />;
      })}
    </ul>
  );
};

export default AllSteps;
