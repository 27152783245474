import React, { useEffect, useRef, useState } from "react";
import { imentor_mobile, back_icon, Notification } from "../../utils/images";
import store from "../../rematch";
import { useSelector } from "react-redux";
import UserMenu from "./UserMenu";
import { removeCookie } from "../../utils";
import { useNavigate } from "react-router-dom";
import SettingsPopup from "../../components/Popup/SettingsPopup";
import NotificationPopup from "../../components/Popup/NotificationPoup";
import MatchedPopup from "../../components/Popup/MatchedPopup";
import { date } from "zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import axiosClient from "../../../src/api/axiosClient";

const Header = ({ routeData }) => {
  const { dispatch } = store;
  const popoverRef = useRef();
  const { title, isBack, className } = routeData;
  const loading = useSelector(
    (state) => state.loading.effects.authModel.getUserDetails
  );
  const userData = useSelector((state) => state.authModel.logInUser);
  const resizeWidth = useSelector((state) => state.homeModel.resizeWidth);
  const state = useSelector((state) => state.eventModel);
  const { isnotification } = state;
  // const handleNotificationPopup = () => {
  //   dispatch.eventModel.setData({ isnotification: !isnotification });
  // };
  const [settingToggle, setSettingToggle] = useState(false);
  const pathname = window.location.pathname;
  const navigate = useNavigate();
  const socketConnected = useSelector(
    (state) => state.socketModel.socketConnected
  );
  const socket = useSelector((state) => state.socketModel.socket);
  const childPaths = pathname.split("/").filter((data) => data);
  const { getNotificationCount } = useSelector((state) => state.eventModel);

  // useEffect(() => {
  //     const payload = {
  //       event: "getCounts",
  //       data: {},
  //     };
  //     dispatch.socketModel.sendEvent({ payload });
  // }, [socketConnected]);
  useEffect(() => {
    if (userData && (userData?.isDel || userData?.isDisabled)) {
      logout();
    }
  }, [userData]);
  useEffect(() => {
    if (settingToggle) {
      document.body.style["overflow-y"] = "hidden";
    }
    return () => {
      document.body.style["overflow-y"] = "auto";
    };
  }, [settingToggle]);
  const logoutUser = async () => {
    try {
        const data = await axiosClient.get('auth/log-out').then((res) => res.data);
        if (!data.error) {
            navigate('/')
            // toaster.showToast('Success', data.msg)
            return;
        }
        // toaster.showToast('Danger', data.msg)
    } catch (error) {
     console.error(error);
    }
}
const logout = async () => {
  try {
    const data = await axiosClient.get('auth/log-out').then((res) => res.data);
    if (!data.error) {
      removeCookie("userDetails");
      localStorage.setItem("userStatus","logged Out")
      store.dispatch({ type: "RESET" });
      navigate("/");
      socket?.emit("leave", { data: { user_id: userData.userId } });
      socket?.close();
        navigate('/')
        // toaster.showToast('Success', data.msg)
        return;
    }
    // toaster.showToast('Danger', data.msg)
} catch (error) {
 console.error(error);
}
   
  };
  const handleBack = () => {
    navigate(`/${childPaths[childPaths.length - 2]}`);
  };
  const handleSettingOpen = () => {
    setSettingToggle(!settingToggle);
  };

  return (
    <>
      <div className={`common_header ${className}`}>
        <div className="common_header_wrapper d_flex_between flex_wrap">
          <div className="left">
            <div className="back_home d_flex align_items_center ">
              <div className="mobile_logo">
                <a href="#">
                  <img onClick={ ()=> navigate(`/home`)} src={imentor_mobile} alt="" />
                </a>
              </div>
              {childPaths.length > 1 && isBack ? (
                <div className="back_home_wrapper d_flex_center">
                  <img
                    src={back_icon}
                    alt=""
                    className="cursor-pointer"
                    onClick={handleBack}
                  />
                  <h5>
                    Back to{" "}
                    <span>
                      {childPaths[childPaths.length - 2]
                        .charAt(0)
                        .toUpperCase() +
                        childPaths[childPaths.length - 2].slice(1)}
                    </span>
                  </h5>
                </div>
              ) : (
                <div className="admin-title">
                  <h5>{title.charAt(0).toUpperCase() + title.slice(1)}</h5>
                </div>
              )}
            </div>
          </div>
          <div className="right d_flex_center">
            <Popover>
              <PopoverTrigger asChild ref={popoverRef}>
                <span
                  className={`common_right_head ${
                    getNotificationCount?.notificationCount > 0
                      ? "notification"
                      : ""
                  }`}
                  // onClick={handleNotificationPopup}
                >
                  <img src={Notification} alt="" />
                </span>
              </PopoverTrigger>
              <PopoverContent className="home_notification_sec">
                <NotificationPopup popoverRef={popoverRef} />
              </PopoverContent>
            </Popover>
            <UserMenu
              loading={loading}
              userData={userData}
              logout={logout}
              handleSettingOpen={handleSettingOpen}
            />
          </div>
        </div>
        {settingToggle && (
          <SettingsPopup handleSettingOpen={handleSettingOpen} />
        )}
        <MatchedPopup />
      </div>
      {resizeWidth && childPaths.length > 1 && isBack && (
        <div
          className={`comman_head content_fixed_header ${
            window.location.pathname.includes("/events/") ? "d_none" : ""
          }`}
        >
          <div className="main-head d_flex_center justify_content_between">
            <div className="back_home_wrapper d_flex_center">
              <img
                src={back_icon}
                alt=""
                className="cursor-pointer"
                onClick={handleBack}
              />
              <h5>
                Back to{" "}
                <span>
                  {childPaths[childPaths.length - 2].charAt(0).toUpperCase() +
                    childPaths[childPaths.length - 2].slice(1)}
                </span>
              </h5>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
