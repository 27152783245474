import PrivateLayout from "../layout/PrivateLayout";
import PublicLayout from "../layout/PublicLayout";
import { memo } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { cookieDecryption, onBoardingHandler, stepByPath } from "../utils";
import OnboardingLayout from "../layout/OnboardingLayout";

const RegisterRouter = ({ title, allowedRoles, layout }) => {
  const auth = cookieDecryption("userDetails");
  const location = useLocation();

  const loggedRole = auth?.role || auth?.userObj?.role;
  const onboardingStep =
    auth?.onboardingStep === 0
      ? auth?.onboardingStep
      : auth?.onboardingStep || auth?.userObj?.onboardingStep;
  const isAccess = allowedRoles.find((role) => role === loggedRole);

  if (auth && isAccess && onboardingStep !== 5) {
    return <Wrapper layout={layout} title={title} />;
  } else if (onboardingStep === 5) {
    return (
      <Navigate
        to={onBoardingHandler(onboardingStep)}
        state={{ from: location }}
        replace
      />
    );
  } else {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }
};

const getLayout = (layout) => {
  switch (layout) {
    case "PUBLIC_LAYOUT": {
      return (
        <PublicLayout>
          <Outlet />
        </PublicLayout>
      );
    }
    case "ONBOARDING_LAYOUT": {
      return (
        <OnboardingLayout>
          <Outlet />
        </OnboardingLayout>
      );
    }
    default: {
      return (
        <PrivateLayout>
          <Outlet />
        </PrivateLayout>
      );
    }
  }
};

const Wrapper = ({ title, layout }) => (
  <HelmetProvider context={{}}>
    <title>{title}</title>
    {getLayout(layout)}
  </HelmetProvider>
);

export default memo(RegisterRouter);