import React, { useEffect, useState } from "react";
import { mentor_logo } from "../../utils/images";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { menteesMenulist, sidebarlist } from "./SidebarArray";
import { useSelector } from "react-redux";
import store from "../../rematch";

const Sidebar = () => {
  const userData = useSelector((state) => state.authModel.logInUser);
  const resizeWidth = useSelector((state) => state.homeModel.resizeWidth);
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch } = store;
  const [, pathname] = location.pathname.split("/");

  const pageChanger = (title, path) => {
    navigate(path, { state: { title: title, path: path } });
    dispatch.eventModel.setData({ isnotification: false });
  };
const partnerAdmin = userData?.partnerAdmin
  return (
    <>{ !resizeWidth &&
    <div className="app mobile_app">
      <a className="logo-box d_flex_center mb-50" href="#">
        <img src={partnerAdmin?.logo || mentor_logo} onClick={ ()=> navigate(`/home`)} style={{width:"137px",height:"50px", objectFit:"cover"}} />
      </a>
      <div className="dashboard-menu">
        <div className="menu-wrapper pt-50">
          <ul className="menu">
            {(userData.role === "Mentee" ? menteesMenulist : sidebarlist).map(({ id, name, Route, icon: Icon }) => {
              return (
                <li
                  key={id}
                  className={`cursor_pointer ${(Route === pathname || pathname?.includes(Route)) &&
                    "active"
                    }`}
                  onClick={() => pageChanger(name, Route)}
                >
                  <a className="flex-center">
                    <span className="menu-icon">
                      <Icon />
                    </span>
                    <span className="menu-text">{name}</span>
                  </a>
                </li>
              );
            })}

          </ul>
        </div>
      </div>
    </div>
    }</>
  );
};

export default Sidebar;
