import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";

import { cn } from "../../lib/utils";
import { Loader } from "lucide-react";

const buttonVariants = cva("flex justify-center", {
  // variants: {
  //   variant: {
  //     default: "bg-gray-900 text-gray-50 hover:bg-gray-900/90 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90",
  //     destructive:
  //       "bg-red-500 text-gray-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-red-50 dark:hover:bg-red-900/90",
  //     outline:
  //       "border border-gray-200 bg-white hover:bg-gray-100 hover:text-gray-900 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50",
  //     secondary:
  //       "bg-gray-100 text-gray-900 hover:bg-gray-100/80 dark:bg-gray-800 dark:text-gray-50 dark:hover:bg-gray-800/80",
  //     ghost: "hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-gray-50",
  //     link: "text-gray-900 underline-offset-4 hover:underline dark:text-gray-50",
  //   },
  //   size: {
  //     default: "h-10 px-4 py-2",
  //     sm: "h-9 rounded-md px-3",
  //     lg: "h-11 rounded-md px-8",
  //     icon: "h-10 w-10",
  //   },
  // },
  // defaultVariants: {
  //   variant: "default",
  //   size: "default",
  // },
});

const Button = React.forwardRef(
  (
    { className, variant, size, asChild = false, loading, children, ...props },
    ref
  ) => {
    // debugger
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(buttonVariants({ className }))}
        disabled={loading}
        ref={ref}
        {...props}
      >
        {loading && <Loader className="animate-spin mr-2 inline-block" />}
        {children}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
