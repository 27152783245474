import React from "react";
import { Close1, tick_circle } from "../../../utils/images";

const PasswordResetSuccess = ({ onContinue }) => {
  return (
    <div className="forgot_model_popup change_pass_model_popup">
      <div className="model_popup_detail">
        <h6>Password has been Reset</h6>
        <div className="flex justify-center" >
          <img src={tick_circle} alt="tick_circle" />
        </div>
        <p>
          Your password has been successfully reset, Please go back to Log In to
          continue
        </p>
        <button className="btn_primary" onClick={() => onContinue("/")}>
          Continue to Log In
        </button>
      </div>
    </div>
  );
};

export default PasswordResetSuccess;
