import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";

const CustomModal = ({ onClose, open, children, className="" }) => {
  return (
    <Dialog onOpenChange={onClose} open={open}>
      <DialogContent className={`max-w-max p-0 rounded-2xl h-[321px] ${className} `} >{children}</DialogContent>
    </Dialog>
  );
};

export default CustomModal;
