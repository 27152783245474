import {
  Decryption,
  Encryption,
  cookieDecryption,
  cookieEncryption,
  expireTime,
} from "../../utils";
import socketIo from "socket.io-client";
import Cookies from "js-cookie";
// import SentEmailPopup from "/components/Popup/SentEmailPopup";
import SentEmailPopup from "../../components/Popup/SentEmailPopup";
import SessionExpiredPopup from "../../components/Popup/SessionExpiredPopup";
import CryptoJS from "crypto-js";

export const getResponse = (data) => {
  return process.env.REACT_APP_DISABLE_ENCRYPTION === "true"
    ? data
    : Decryption(data);
};

const requestHandler = (event, data) => {
  switch (event) {
    case "join": {
      return data;
    }
    default: {
      return { data, event };
    }
  }
};
const getChangedLoader = (event) => {
  switch (event) {
    case "getMsg": {
      return "chatLoader";
    }
    default: {
      return "nothing";
    }
  }
};
const currentUrl = window.location.href;
const url = new URL(currentUrl);
const queryString = url.search;
const params = new URLSearchParams(queryString);
const decryption = (data) => {
  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(data),
    process.env.REACT_APP_SECRET_KEY
  );
  const cryptoBytes = bytes.toString(CryptoJS.enc.Utf8);
  let decryptedData = cryptoBytes ? JSON.parse(cryptoBytes) : null;
  return decryptedData;
};
const paramValue = params.get("query");
const query = decryption(paramValue);
const socketModel = {
  state: {
    socketConnected: false,
    socketLoaders: {
      join: false,
      getMsgList: false,
      sendMsg: false,
      getMsg: false,
      getNotifications: false,
      chatLoader: false,
    },
  },
  reducers: {
    setConnected(state, payload) {
      state = { ...state, socketConnected: payload };
      return state;
    },
    setSocket(state, payload) {
      state = { ...state, socket: payload };
      return state;
    },
    setLoader(state, payload) {
      state = {
        ...state,
        socketLoaders: {
          ...state.socketLoaders,
          [payload.name]: payload.data,
        },
      };
      return state;
    },
  },
  effects: (dispatch) => ({
    getSocket({ token, userData }, state) {
      // const sock = socketIo('http://172.20.11.171:4000', {
      // const sock = socketIo('https://cmsapi.imentor.org/', {
      const sock = socketIo(process.env.REACT_APP_SOCKET_API_KEY, {
        transports: ["websocket"],
        // query: `user=${token}`,
      });
      sock.on("connect", () => {
        const payload = {
          event: "join",
          data: {
            user_id: userData?.userId,
            token: userData?.token,
          },
        };
        dispatch.socketModel.sendEvent({ payload });
      });
      this.setSocket(sock);
    },
    sendEvent({ payload, changeLoader }, state) {
      const { event, data } = payload;
      const socket = state?.socketModel?.socket;
      if (!changeLoader) {
        this.setLoader({ name: event, data: true });
      } else {
        this.setLoader({ name: changeLoader, data: true });
      }

      if (!socket?.connected) {
        return;
      }

      socket?.emit(
        event === "join" ? "join" : "request",
        process.env.REACT_APP_DISABLE_ENCRYPTION === "true"
          ? requestHandler(event, data)
          : Encryption(requestHandler(event, data))
      );
    },
    socketResponse({ response }, state) {
      this.setLoader({ name: response?.event, data: false });
      this.setLoader({ name: getChangedLoader(response?.event), data: false });
      switch (response?.event) {
        case "join": {
          this.setConnected(true);
          break;
        }
        case "error": {
          if (state.messagesModel.isErrorOccured) {
            break;
          }
          dispatch.messagesModel.setMsgState({
            name: "isErrorOccured",
            data: true,
          });
          dispatch.dialogModel.showDialog({
            component: () => <SessionExpiredPopup />,
            className: "alert_popup_height",
          });
          break;
        }
        case "partnerShortNameUpdate": {
          const currentUrl = window.location.href;
          if (
            currentUrl?.includes("messages") &&
            currentUrl?.match(/messages\/([^?]+)/)?.[1]?.length == 24
          ) {
            const groupId = currentUrl?.match(/messages\/([^?]+)/)?.[1];
            const connectionlist = state.messagesModel.connectionList;
            const conversationlistweq = state.messagesModel.messageList;
            const conversationlist =
              state.messagesModel.messageList?.messageData;

            const index = connectionlist?.findIndex(
              (item) => item?.group?._id === groupId
            );
            const uniqueChats = conversationlist.map((current) => {
              if (
                current.messageType === "Welcome Message" &&
                current.messageCategory === "Welcome Prompt"
              ) {
                return {
                  ...current,
                  message: current?.content?.replace(
                    ":attributes",
                    response?.data?.shortName
                  ),
                  groupName: response?.data?.shortName,
                };
              } else {
                return {
                  ...current,
                  groupName: response?.data?.shortName,
                };
              }
            });
            const updatedData = {
              ...conversationlistweq,
              messageData: uniqueChats,
            };

            if (index !== -1) {
              const updatedGroup = {
                ...connectionlist[index].group,
                groupName: response?.data?.shortName,
              };
              connectionlist[index] = {
                ...connectionlist[index],
                group: updatedGroup,
              };
            }

            dispatch.messagesModel.setMsgState({
              name: "messageList",
              data: updatedData,
            });
            dispatch.messagesModel.setMsgState({
              name: "connectionList",
              data: connectionlist,
            });
          } else {
            const connectionlist = state.messagesModel.connectionList;

            const index = connectionlist?.findIndex(
              (item) => item?.group?._id === response?.data?.groupId
            );

            if (index !== -1) {
              const updatedGroup = {
                ...connectionlist[index].group,
                groupName: response?.data?.shortName,
              };
              connectionlist[index] = {
                ...connectionlist[index],
                group: updatedGroup,
              };
            }

            dispatch.messagesModel.setMsgState({
              name: "connectionList",
              data: connectionlist,
            });
          }
          break;
        }
        case "getMsgList": {
          const userId = state.messagesModel.chatId;
          const logInUser = state.authModel.logInUser;
          const sokcetChatId = state.messagesModel?.sokcetChatId
          let temp = response?.data?.docs.find(
            (_) =>
              _?.users?._id === userId &&
              _?.lastMessage?.senderId !== logInUser?.userId &&
              !_?.lastMessage?.isRead
          );
          const isMsg =
            typeof temp?.lastMessage?.senderId === "string"
              ? temp?.lastMessage?.senderId
              : temp?.lastMessage?.senderId?._id;

          let conList = response?.data?.docs;
          const payload = {
            event: "getMsg",
            data: {
              limit:
                temp?.unreadMessagesCount > 10 ? temp?.unreadMessagesCount : 10,
              ...(query?.groupId && {
                groupId: query?.groupId,
              }),
              chatId: query?.chatId || sokcetChatId,
            },
          };
          if (isMsg === userId) {
            const payload = {
              event: "readMsg",
              data: { chatId: temp?.chatId, senderId: isMsg },
            };
            dispatch.socketModel.sendEvent({ payload });
            conList = conList?.map((e) =>
              e?.users?._id === userId
                ? { ...e, unreadMessagesCount: 0 }
                : { ...e }
            );
          }
          if (userId) {
            
            dispatch.socketModel.sendEvent({ payload });
          }
          dispatch.messagesModel.setMsgState({
            name: "connectionList",
            data: conList,
          });
          console.log(state.messagesModel,'state.messagesModel.messageList');
          break;
        }
        case "sendMsg": {
          const newMessage = response.data.conversationlist;
          const userId = state.messagesModel.chatId;
          const list = state.messagesModel.connectionList;
          const logInUser = state.authModel.logInUser;
          const msgState = state.messagesModel.messageList || {};
          let temp = [...state.messagesModel.connectionList];
          let index = temp.findIndex((data) => {
            return data?.chatId === newMessage.chatId;
          });
          if (index > -1) {
            let latestChat = {
              ...temp[index],
              lastMessage: newMessage,
              unreadMessagesCount:
                userId !== newMessage?.receiver?._id
                  ? temp[index]?.unreadMessagesCount + 1
                  : 0,
            };
            temp.splice(index, 1);
            temp.unshift(latestChat);
          } else {
            temp.unshift({
              ...newMessage,
              receiver: newMessage?.receiver._id,
              user:
                newMessage?.receiver?._id === state.authModel.logInUser?.userId
                  ? newMessage.sender
                  : newMessage.receiver,
              lastMessage: newMessage,
              unreadMessagesCount: newMessage?.lastMessage?.isRead ? 0 : 1,
            });
          }
          dispatch.messagesModel.setMsgState({
            name: "connectionList",
            data: temp,
          });
          const latestMessage =
            msgState?.messageData[0]?.chatId === query?.chatId;
          const updatedData = {
            ...msgState,
            messageData: [...(msgState?.messageData || []), newMessage],
            sendMessage: true,
          };
          if (query && query?.chatId === newMessage?.chatId) {
            const payload = {
              event: "readMsg",
              data: { chatId: newMessage?.chatId, senderId: userId },
            };
            dispatch.socketModel.sendEvent({ payload });

            if (latestMessage) {
              dispatch.messagesModel.setMsgState({
                name: "messageList",
                data: updatedData,
              });
            }
            dispatch.messagesModel.setMsgState({
              name: "connectionList",
              data: list?.map((e) => {
                return (e?.user?._id || e?.group?._id) === userId
                  ? {
                      ...e,
                      unreadMessagesCount: 0,
                      lastMessage: { ...newMessage },
                    }
                  : { ...e };
              }),
            });
            if (
              newMessage?.content === "Hello, How are you!" ||
              newMessage?.content === "Can you share me your CV" ||
              newMessage?.content === "Can you share me your CV" ||
              newMessage?.content === "Any update?" ||
              newMessage?.content === "Thank you!"
            ) {
              const payload = {
                event: "getMsgList",
                data: { search: "" },
              };
              dispatch.socketModel.sendEvent({ payload });
            }
          }
          if (newMessage?.sender?._id === logInUser.userId) {
            dispatch.messagesModel.setMsgState({
              name: "messageList",
              data: updatedData,
            });
          }

          break;
        }
        case "getMsg": {
          // debugger
          const msgState = state.messagesModel.messageList || {};
          const flatlist = msgState?.messageData?.flat();
          const list = state.messagesModel.connectionList;
          const messageData = [
            ...(response?.data?.conversationlist?.data || []).reverse(),
            ...(flatlist || []),
          ];
          let checkDuplicateData = messageData?.filter((obj, index) => {
            if (obj?.length > 0) {
              return true;
            }
            return index === messageData.findIndex((o) => obj._id === o._id);
          });
          let imgArray = [];
          let fileArray = [];
          let testarray = (checkDuplicateData || []).reduce(
            (acc, cur, currentIndex) => {
              if (cur && cur.length > 0) {
                acc.push(cur);
                return acc;
              }
              if (cur && cur.chType === "Pair" && cur.messageType === "Media") {
                imgArray.push(cur);
              } else if (
                cur &&
                cur.chType === "Pair" &&
                cur.messageType === "File"
              ) {
                fileArray.push(cur);
              } else {
                if (fileArray.length > 0) {
                  acc.push([...fileArray]);
                  fileArray = [];
                }
                if (imgArray.length > 0) {
                  acc.push([...imgArray]);
                  imgArray = [];
                }
                acc.push({ ...cur });
              }
              if (
                currentIndex === checkDuplicateData.length - 1 &&
                imgArray.length > 0
              ) {
                acc.push([...imgArray]);
              }
              if (
                currentIndex === checkDuplicateData.length - 1 &&
                fileArray.length > 0
              ) {
                acc.push([...fileArray]);
              }
              return acc;
            },
            []
          );
          console.log(testarray,'testarray');
          const dispatchData = {
            ...(response?.data?.conversationlist || {}),
            ...response.data,
            messageData: testarray,
          }
          console.log(dispatchData,'dispatchData');
          dispatch.messagesModel.setMsgState({
            name: "messageList",
            data: dispatchData
          });
          let temp = response?.data?.conversationlist?.data?.at(-1);
          const userId = state.authModel.logInUser?.userId;
          if (userId !== temp?.sender?._id) {
            const payload = {
              event: "readMsg",
              data: { chatId: temp?.chatId, senderId: temp?.sender?._id },
            };
            dispatch.socketModel.sendEvent({ payload });
            dispatch.messagesModel.setMsgState({
              name: "connectionList",
              data: list?.map((e) => {
                return testarray[0]?.chatId === e?.chatId
                  ? { ...e, unreadMessagesCount: 0 }
                  : { ...e };
              }),
            });
          }
          break;
        }
        case "getNotifications": {
          dispatch.eventModel.setData({ getNotificationList: response?.data });
          break;
        }
        case "notification": {
          const filteredArray = state?.eventModel?.getNotificationList?.data?.filter(
            (notification) => {
              if (
                (notification?.type === "InvitationDeclined" ||
                  notification?.type === "InvitationApproved") &&
                notification?.dataId === response?.data?.dataId
              ) {
                return (
                  (notification.from ?? notification?.from_id) !==
                  response?.data?.from_id
                );
              }
              return true;
            }
          );
          dispatch.eventModel.setData({
            getNotificationList: {
              ...state?.eventModel?.getNotificationList,
              data: filteredArray && [response?.data, ...filteredArray],
            },
          });
          break;
        }
        case "sendMsgReaction": {
          let data = response.data;
          const messageList = state.messagesModel.messageList || {};
          let messageData = messageList?.messageData || [];
          const currentUser = state.messagesModel.chatId;

          let index = messageList.messageData.findIndex(
            (_) => _._id === data.messageId
          );

          let updatedReactions = (message) => {
            console.log(message);
            return message.reactions
              .filter((reaction) => {
                if (reaction.type === data.reaction) {
                  return reaction.users.some(
                    (user) => user._id !== state.authModel.logInUser?.userId
                  );
                }
                return true;
              })
              .map((reaction) => {
                if (reaction.type === data.reaction) {
                  return {
                    ...reaction,
                    count: --reaction.count,
                    userReacted: false,
                    users: reaction.users.filter(
                      (user) => user._id !== state.authModel.logInUser?.userId
                    ),
                  };
                }
                return { ...reaction };
              });
          };

          if (data.messageId !== response?.lastMessage?._id) {
            messageData[index] = {
              ...messageData[index],
              reactions: updatedReactions(messageData[index]),
            };
          } else {
            messageData[index] = {
              ...messageData[index],
              reactions: response?.lastMessage?.reactions || [],
            };
          }
          let updatedData = {
            ...messageList,
            messageData: [...messageData],
            sendReaction: true,
          };

          const newData = state.messagesModel.connectionList?.map((e) =>
            e?.lastMessage?.chatId === response?.lastMessage?.chatId
              ? { ...e, lastMessage: { ...response?.lastMessage } }
              : e
          );
          dispatch.messagesModel.setMsgState({
            name: "messageList",
            data: updatedData,
          });
          dispatch.messagesModel.setMsgState({
            name: "connectionList",
            data: newData,
          });

          break;
        }
        case "getCounts": {
          dispatch.eventModel.setData({ getNotificationCount: response?.data });
          break;
        }

        case "readNotification": {
          if (response?.success) {
            const payload = {
              event: "getNotifications",
              data: { page: 1, limit: 10 },
            };
          }
          break;
        }
        case "matchedFound": {
          dispatch.homeModel.setData({
            name: "matchedPopup",
            data: !state.homeModel.matchedPopup,
          });
          dispatch.homeModel.setData({
            name: "matchedData",
            data: response?.data,
          });
          break;
        }
        case "readMsg": {
          let updatedData = {
            ...state?.messagesModel?.messageList,
            messageData: state?.messagesModel?.messageList?.messageData?.map(
              (e) => {
                if (e.length) {
                  return e;
                }
                return { ...e, isRead: true };
              }
            ),
          };
          dispatch.messagesModel.setMsgState({
            name: "messageList",
            data: updatedData,
          });
          const list = state.messagesModel.connectionList;
          const updatedList = list.map((e) => {
            if (e.chatId == response.data.chatId) {
              return {
                ...e,
                lastMessage: {
                  ...e.lastMessage,
                  unreadMessagesCount: 0,
                  isRead: true,
                },
              };
            } else {
              return { ...e };
            }
          });
          dispatch.messagesModel.setMsgState({
            name: "connectionList",
            data: updatedList,
          });
          break;
        }
        case "lessonCompleted": {
          if (state.authModel.logInUser.role == "Mentor") {
          } else if (state.authModel.logInUser.role == "Mentee") {
          }
          state.preMatchModel.redirectURLWin.close();
          break;
        }

        case "userInactivated": {
          if (
            (state.authModel.logInUser?.userId,
            state?.messagesModel?.messageList?.messageData?.[0]?.receiverId
              ?._id)
          ) {
            let temp = state?.messagesModel?.messageList?.messageData;
            temp[0] = {
              ...temp[0],
              receiverId: {
                ...temp[0].receiverId,
                isDel: response?.data?.isDel,
                isDisabled: response?.data?.isDisabled,
              },
            };
            let updatedData = {
              ...state?.messagesModel?.messageList,
              messageData: temp,
            };
            dispatch.messagesModel.setMsgState({
              name: "messageList",
              data: updatedData,
            });
          }
          break;
        }

        case "progressPercentage": {
          if (state.contentProgressModel.contentList?.length > 0) {
            dispatch.contentProgressModel.setData({
              contentList: state.contentProgressModel.contentList?.map((e) =>
                e?.enrollId === response?.data?.enrollId
                  ? {
                      ...e,
                      percentage_completed:
                        response?.data?.progressInPercentage,
                      completed:
                        response?.data?.progressInPercentage === "1.0"
                          ? true
                          : false,
                    }
                  : { ...e }
              ),
            });
          }
          if (state.authModel.logInUser.role === "Mentee") {
            dispatch.homeModel.setData({
              name: "assignProjectList",
              data: state.homeModel.assignProjectList?.map((e) =>
                e?.enrollId === response?.data?.enrollId
                  ? {
                      ...e,
                      percentageCompleted: (
                        response?.data?.progressInPercentage * 100
                      )
                        .toFixed(2)
                        .replace(/\.00$/, ""),
                    }
                  : { ...e }
              ),
            });
          }
          break;
        }
        case "timezoneUpdate": {
          let auth = cookieDecryption("userDetails");
          auth.timezone = response?.data?.timezone;
          cookieEncryption("userDetails", auth);
          break;
        }

        case "leave": {
        }

        default:
          break;
      }
    },
  }),
};

export default socketModel;
