const TOAST_PROPERTIES = [
  {
    id: Math.floor(Math.random() * 101 + 1),
    title: "Success",
    description: "This is a success toast component",
    backgroundColor: "#5cb85c",
  },
  {
    id: Math.floor(Math.random() * 101 + 1),
    title: "Danger",
    description: "This is an error toast component",
    backgroundColor: "#d9534f",
  },
  {
    id: Math.floor(Math.random() * 101 + 1),
    title: "Info",
    description: "This is an info toast component",
    backgroundColor: "#5bc0de",
  },
  {
    id: Math.floor(Math.random() * 101 + 1),
    title: "Warning",
    description: "This is a warning toast component",
    backgroundColor: "#f0ad4e",
  },
];
const TOAST_PROPERTIES_PREMATCH = [
  {
    id: Math.floor(Math.random() * 101 + 1),
    title: "Danger",
    description: "This is an error toast component",
    backgroundColor: "#d9534f",
  },
  {
    id: Math.floor(Math.random() * 101 + 1),
    title: "Orientation",
    description: "This is an Orientation OOPS toast component",
    backgroundColor: "#5bc0de",
  },
  {
    id: Math.floor(Math.random() * 101 + 1),
    title: "Screening",
    description: "This is an Screening OOPS toast component",
    backgroundColor: "#5bc0de",
  },
];

const toastModel = {
  state: {
    list: [],
    timeSlotList: [],
  },
  reducers: {
    showToast(state, { message, value }) {
      const toastProperties = TOAST_PROPERTIES.find(
        (toast) => toast.title === value
      );
      return {
        ...state,
        list: [...state.list, { ...toastProperties, description: message }],
      };
    },
    showTimeSlot(state, { value }) {
      if (value === "Break") {
        return {...state,timeSlotList:[]}
      } else {
        const toastProperties = TOAST_PROPERTIES_PREMATCH.find(
          (toast) => toast.title === value
        );
        return { ...state, timeSlotList: [toastProperties] };
      }
    },
  },
  effects: () => ({}),
};

export default toastModel;
