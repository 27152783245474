// // audio-recording.js ---------------
// //API to handle audio recording

export const audioRecorder = {
  /** Stores the recorded audio as Blob objects of audio data as the recording continues*/
  audioBlobs: [] /*of type Blob[]*/,
  /** Stores the reference of the MediaRecorder instance that handles the MediaStream when recording starts*/
  mediaRecorder: null /*of type MediaRecorder*/,
  /** Stores the reference to the stream currently capturing the audio*/
  streamBeingCaptured: null /*of type MediaStream*/,
  /** Start recording the audio
   * @returns {Promise} - returns a promise that resolves if audio recording successfully started
   */
  start: function () {
    //Feature Detection
    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      //Feature is not supported in browser
      //return a custom error
      return Promise.reject(
        new Error(
          "mediaDevices API or getUserMedia method is not supported in this browser."
        )
      );
    } else {
      //Feature is supported in browser

      //create an audio stream
      return (
        navigator.mediaDevices
          .getUserMedia({ audio: true } /*of type MediaStreamConstraints*/)
          //returns a promise that resolves to the audio stream
          .then((stream) /*of type MediaStream*/ => {
            //save the reference of the stream to be able to stop it when necessary
            audioRecorder.streamBeingCaptured = stream;

            //create a media recorder instance by passing that stream into the MediaRecorder constructor
            audioRecorder.mediaRecorder = new MediaRecorder(
              stream
            ); /*the MediaRecorder interface of the MediaStream Recording
                    API provides functionality to easily record media*/

            //clear previously saved audio Blobs, if any
            audioRecorder.audioBlobs = [];

            //add a dataavailable event listener in order to store the audio data Blobs when recording
            audioRecorder.mediaRecorder.addEventListener(
              "dataavailable",
              (event) => {
                //store audio Blob object
                audioRecorder.audioBlobs.push(event.data);
              }
            );

            //start the recording by calling the start method on the media recorder
            audioRecorder.mediaRecorder.start();
          })
      );

      /* errors are not handled in the API because if its handled and the promise is chained, the .then after the catch will be executed*/
    }
  },
  /** Stop the started audio recording
   * @returns {Promise} - returns a promise that resolves to the audio as a blob file
   */
  stop: function (audioData = []) {
    //return a promise that would return the blob or URL of the recording
    return new Promise((resolve) => {
      //save audio type to pass to set the Blob type
      let mimeType = audioRecorder.mediaRecorder.mimeType;
      //listen to the stop event in order to create & return a single Blob object
      audioRecorder.mediaRecorder.addEventListener("stop", () => {
        //create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
        let audioAsblob = new Blob(
          [...audioData.audioBlobsData, ...audioRecorder.audioBlobs],
          { type: mimeType }
        );
        //resolve promise with the single audio blob representing the recorded audio
        resolve({ audioAsblob, blob: audioRecorder.audioBlobs[0] });
      });
      audioRecorder.cancel(audioData);
    });
  },
  /** Cancel audio recording*/
  cancel: function (audioData) {
    //stop the recording feature
    if (!audioData.pause) {
      audioRecorder.mediaRecorder.stop();
      //stop all the tracks on the active stream in order to stop the stream
      audioRecorder.stopStream();

      //reset API properties for next recording
      audioRecorder.resetRecordingProperties();
    }
  },
  /** Stop all the tracks on the active stream in order to stop the stream and remove
   * the red flashing dot showing in the tab
   */
  stopStream: function () {
    //stopping the capturing request by stopping all the tracks on the active stream
    audioRecorder.streamBeingCaptured
      .getTracks() //get all tracks from the stream
      .forEach((track) /*of type MediaStreamTrack*/ => track.stop()); //stop each one
  },
  /** Reset all the recording properties including the media recorder and stream being captured*/
  resetRecordingProperties: function () {
    audioRecorder.mediaRecorder = null;
    audioRecorder.streamBeingCaptured = null;

    /*No need to remove event listeners attached to mediaRecorder as
        If a DOM element which is removed is reference-free (no references pointing to it), the element itself is picked
        up by the garbage collector as well as any event handlers/listeners associated with it.
        getEventListeners(audioRecorder.mediaRecorder) will return an empty array of events.*/
  },
  // Pause the ongoing audio recording
  pause: function () {
    // Pause the recording by pausing the media recorder
    audioRecorder.mediaRecorder.pause();
  },

  // Resume a paused audio recording
  resume: function () {
    // Resume the recording by resuming the media recorder
    audioRecorder.mediaRecorder.resume();
  },
};

// export function computeElapsedTime(startTime) {
//   //record end time
//   let endTime = new Date();

//   //time difference in ms
//   let timeDiff = endTime - startTime;

//   //convert time difference from ms to seconds
//   timeDiff = timeDiff / 1000;

//   //extract integer seconds that dont form a minute using %
//   let seconds = Math.floor(timeDiff % 60); //ignoring uncomplete seconds (floor)

//   //pad seconds with a zero if neccessary
//   seconds = seconds < 10 ? "0" + seconds : seconds;

//   //convert time difference from seconds to minutes using %
//   timeDiff = Math.floor(timeDiff / 60);

//   //extract integer minutes that don't form an hour using %
//   let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, becase they've been handled as seconds
//   minutes = minutes < 10 ? "0" + minutes : minutes;

//   //convert time difference from minutes to hours
//   timeDiff = Math.floor(timeDiff / 60);

//   //extract integer hours that don't form a day using %
//   let hours = timeDiff % 24; //no need to floor possible incomplete hours, becase they've been handled as seconds

//   //convert time difference from hours to days
//   timeDiff = Math.floor(timeDiff / 24);

//   // the rest of timeDiff is number of days
//   let days = timeDiff; //add days to hours

//   let totalHours = hours + days * 24;
//   totalHours = totalHours < 10 ? "0" + totalHours : totalHours;

//   if (totalHours === "00") {
//     return minutes + ":" + seconds;
//   } else {
//     return totalHours + ":" + minutes + ":" + seconds;
//   }
// }

export function computeElapsedTime(startTime, pauseIntervals) {
  // Record end time
  let endTime = new Date();

  // Calculate the total duration excluding pause intervals
  let totalDuration = endTime - startTime;

  // Calculate the total paused time from pause intervals
  let totalPausedTime = 0;
  for (const [pauseStart, pauseEnd] of pauseIntervals) {
    totalPausedTime += pauseEnd - pauseStart;
  }

  // Subtract total paused time from total duration
  let elapsedDuration = totalDuration - totalPausedTime;

  // Convert elapsed duration from ms to seconds
  elapsedDuration = elapsedDuration / 1000;

  // Calculate hours, minutes, and seconds
  let hours = Math.floor(elapsedDuration / 3600);
  let minutes = Math.floor((elapsedDuration % 3600) / 60);
  let seconds = Math.round(elapsedDuration % 60);

  // Format hours, minutes, and seconds as two-digit strings
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  // Create the formatted elapsed time string
  const formattedElapsedTime = `${hours}:${minutes}:${seconds}`;

  // Return the formatted elapsed time
  return formattedElapsedTime;
}

export async function getAudioDuration(mp3Blob) {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const arrayBuffer = fileReader.result;

      try {
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        // Calculate the duration based on the AudioBuffer's duration property
        const audioDuration = Math.floor(audioBuffer.duration).toFixed(0);
        resolve(audioDuration);
      } catch (error) {
        reject(error);
      }
    };

    fileReader.readAsArrayBuffer(mp3Blob);
  });
}
