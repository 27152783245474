import React, { useEffect, useState } from "react";
import {
  logos_google_meet,
  pop_close,
  accept_circle,
} from "../../../utils/images";
import { useNavigate } from "react-router-dom";
import { covertUTCtolocal } from "../../../pages/PrematchTodo";
import moment from "moment";

const OrientationModal = ({ details, setShowModal, title, step }) => {
  const [isDisable, setisDisable] = useState(true);
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    // Get the day, month, and year
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "long" });
    const year = dateObject.getFullYear();

    const addOrdinalIndicator = (number) => {
      const j = number % 10,
        k = number % 100;
      if (j === 1 && k !== 11) {
        return number + "st";
      }
      if (j === 2 && k !== 12) {
        return number + "nd";
      }
      if (j === 3 && k !== 13) {
        return number + "rd";
      }
      return number + "th";
    };

    const formattedDate = `${dateObject.toLocaleString("default", {
      weekday: "long",
    })} ${addOrdinalIndicator(day)} ${month}, ${year}`;
    return formattedDate;
  };
  const localDate = covertUTCtolocal(details.start_date, "DATE");
  const startTime = covertUTCtolocal(details.start_date, "TIME");
  const endTime = covertUTCtolocal(details.end_date, "TIME");
  const formattedDate = formatDate(localDate);
  const finalFormattedDate = `${formattedDate} ${startTime}-${endTime}`;

  const handleReschedule = () => {
    if (step == 2) {
      navigate("/home/step2", { state: { reschedule: "true" } });
    } else if (step == 3) {
      navigate("/home/step3", { state: { reschedule: "true" } });
    }
  };
  const handleJoinNow = (url) => {
    window.open(url, "_blank");
  };
  const isCurrentTimeBetween = (startDate, endDate) => {
    const currentTime = moment();
    let newStartDate = moment(startDate).subtract(1, 'seconds');
    let newEndDate = moment(endDate).subtract(1, 'seconds');
    if (currentTime.isBetween(newStartDate, newEndDate)) {
      setisDisable(false);
    }
  };
  useEffect(() => {
    isCurrentTimeBetween(details?.start_date, details?.end_date);
  }, [details]);
  return (
    <div className="schedule_sec_popup">
      <div className="i_popup">
        <div className="i_popup_content">
          <div className="i_popup_header d_flex_between popup_center_title">
            <h5 className="i_popup_title w_100 text_center">{title}</h5>
            <div
              className="i_close cursor_pointer"
              onClick={() => setShowModal(false)}
            >
              <img src={pop_close} alt="close" classNameName="cursor_pointer" />
            </div>
          </div>
          <div className="all_inner_content">
          <h4 className="college_txt text_center border_bottom_1">{details?.event_name}</h4>
            <div className="pop_details">
              {/* <div className="sub_data google_meet_inner d_flex_center">
                <span className="google_meet_bg">
                  <img src={logos_google_meet} alt="" />
                </span>
                <p>Google Meet</p>
              </div> */}
              <div className="sub_data border_bottom_1">
                <h4 className="text_center">Time & Date</h4>
                <p>{finalFormattedDate}</p>
                <a href="https://meet.google.com/cnh-gtsu-xbd" target="blank">
                  meet.google.com/cnh-gtsu-xbd
                </a>
              </div>

              <div className="sub_data_btn d_flex_center">
                <button className="upload_img" onClick={handleReschedule}>
                  Re-Schedule
                </button>
                <button
                  disabled={isDisable}
                  className={`upload_img join_btn ${
                    isDisable ? "disable" : ""
                  }`}
                  onClick={() =>
                    handleJoinNow("https://meet.google.com/cnh-gtsu-xbd")
                  }
                >
                  <img src={accept_circle} alt="" />
                  Join now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrientationModal;
