const dialogModel = {
  state: {
    component: () => <></>,
    open: false,
    className: ""
  },
  reducers: {
    showDialog(state, { component = <></>, className = "" }) {
      return { ...state, component, open: true, className };
    },
    hideDialog(state, { className = "" }) {
      return { ...state, component: () => <></>, open: false, className };
    },
  },
  effects: () => ({}),
};

export default dialogModel;
