import { useEffect } from "react";
import { common_loader } from "../../utils/images";

const Loader = () => {
  useEffect(() => {
    window.scrollTo(0, -100);
    document.body.style["overflow-y"] = "hidden";
    return () => {
      document.body.style["overflow-y"] = "auto";
    };
  }, []);
  return (
    <div className="loader_bg">
      <div className="loader">
        <img src={common_loader} alt="" />
      </div>
    </div>
  );
};

export default Loader;
