import React, { useEffect, useState } from "react";
import { Close, cookie, up_right_cookies } from "../../../utils/images";
import { cookieDecryption, cookieEncryption } from "../../../utils";
import { useSelector } from "react-redux";
import store from "../../../rematch";

const CookiePopup = () => {
  const [cookiess, setcookiess] = useState(false);
  const isCookiePopup = useSelector((state)=>state?.authModel?.isCookiePopup)
  const {dispatch}=store

  const isCookieAccepted = cookieDecryption("isCookieAccepted");
  useEffect(() => {
    if(isCookieAccepted?.isCookieAccepted){
        dispatch?.authModel?.setUserDetails({name:"isCookiePopup",data:false})
    }
  }, [isCookieAccepted])
  const handleCookie = (type) => {
    dispatch?.authModel?.setUserDetails({name:"isCookiePopup",data:false})
    if (type === "Decline") {
      let temp = { isCookieAccepted: false };
      // dispatch.homeModel.setData({ name: "isCookieAccepted" });
      // temp.isCookieAccepted = true;
      cookieEncryption("isCookieAccepted", temp);
      setcookiess(true);
    } else if (type === "Accept") {
      let temp = { isCookieAccepted: true };
      // dispatch.homeModel.setData({ name: "isCookieAccepted", data: true });
      cookieEncryption("isCookieAccepted", temp);
      setcookiess(true);

      // dispatch?.homeModel?.acceptCookies({ isCookieAccepted: true });
    }
  };
  return (
    <>
      {isCookiePopup && (
        <div className="cookies_popup_wrapper relative z-[500]">
          <div className="ck_popup_box">
            <div className="d_flex">
              <div className="ck_img">
                <img src={cookie} alt="cookies" />
              </div>
              <div className="ck_detail">
                <div
                  className="ck_popup_close"
                  onClick={() => handleCookie("Decline")}
                >
                  <img src={Close} alt="" />
                </div>
                <h5>Cookies</h5>
                <p className="ck_desc">
                  By clicking “Accept All Cookies”, you agree to the storing of
                  cookies on your device to enhance site navigation, analyze
                  site usage, and assist in our marketing efforts.{" "}
                  <a href="https://imentor.org/privacy-policy" target="_blank">
                    Privacy Policy{" "}
                    <img
                      className="d_inline_block"
                      src={up_right_cookies}
                      alt="up_right"
                    />
                  </a>
                </p>
              </div>
            </div>
            <div
              className="accept_ck_btn"
              onClick={() => handleCookie("Accept")}
            >
              <a href="javascript:void(0)" className="btn btn_primary">
                Accept All Cookies
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiePopup;
