import {
  cookieDecryption,
  cookieEncryption,
  onBoardingHandler,
  removeCookie,
  stepsObjectMaker,
} from "../../utils";
import axiosClient from "../../api/axiosClient";
import Cookies from "js-cookie";

const onBoardingModel = {
  state: {
    onboardingSteps: {
      userObj: {},
      additionalInfo: {},
      answerByMentors: {},
    },
    stateList: [],
    schoolOrInstitute: [],
    schoolOrInstitute_label: [],
    stepsFormData: {
      basicSteps: [],
      questionSchema: null,
      formatedData: [],
    },
    isSaveClicked: false,
    isProfilePic: false,
  },
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        [payload.name]: payload.data,
      };
    },
  },
  effects: (dispatch) => ({
    async addOnboardingStep1({ payload, handleNavigate }, state) {
      await axiosClient
        .post("auth/updateUserBasicInfo", payload)
        .then((res) => {
          if (res.data.code === 200) {
            let auth = cookieDecryption("userDetails");
            auth = {
              ...auth,
              onboardingStep: 2,
              userObj: { ...auth.userObj, onboardingStep: 2 },
            };
            cookieEncryption("userDetails", auth);
            dispatch.authModel.setUserDetails({
              name: "logInUser",
              data: auth,
            });
            this.setData({
              name: "onboardingSteps",
              data: {
                ...state.onBoardingModel.onboardingSteps,
                userObj: {
                  ...state.onBoardingModel.onboardingSteps.userObj,
                  onboardingStep: 2,
                },
              },
            });
            handleNavigate(onBoardingHandler(2));
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async addOnboardingStep1Exit({ payload, handleNavigate }, state) {
      await axiosClient
        .post("auth/updateUserBasicInfo", payload)
        .then((res) => {
          if (res.data.code === 200) {
            Cookies.remove("userDetails");
            handleNavigate("/");
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        })
        .finally(() => {
          this.setData({ name: "isSaveClicked", data: false });
        });
    },
    async addOnboardingStep2({ payload, handleNavigate }, state) {
      await axiosClient
        .post("auth/updateOnboardingDetails", payload)
        .then((res) => {
          if (res.data.code === 200) {
            let auth = cookieDecryption("userDetails");
            auth = {
              ...auth,
              onboardingStep: 3,
              userObj: { ...auth.userObj, onboardingStep: 3 },
            };
            cookieEncryption("userDetails", auth);
            dispatch.authModel.setUserDetails({
              name: "logInUser",
              data: auth,
            });
            this.setData({
              name: "onboardingSteps",
              data: {
                ...state.onBoardingModel.onboardingSteps,
                userObj: {
                  ...state.onBoardingModel.onboardingSteps.userObj,
                  onboardingStep: 3,
                },
              },
            });
            handleNavigate(onBoardingHandler(3));
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async addOnboardingStep2Exit({ payload, handleNavigate }, state) {
      await axiosClient
        .post("auth/updateOnboardingDetails", payload)
        .then((res) => {
          if (res.data.code === 200) {
            Cookies.remove("userDetails");
            handleNavigate("/");
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        })
        .finally(() => {
          this.setData({ name: "isSaveClicked", data: false });
        });
    },
    async addOnboardingStep3({ payload, handleNavigate }, state) {
      await axiosClient
        .post("auth/updateOnboardingDetails", payload)
        .then((res) => {
          if (res.data.code === 200) {
            let auth = cookieDecryption("userDetails");
            auth = {
              ...auth,
              onboardingStep: 4,
              userObj: { ...auth.userObj, onboardingStep: 4 },
            };
            cookieEncryption("userDetails", auth);
            dispatch.authModel.setUserDetails({
              name: "logInUser",
              data: auth,
            });
            this.setData({
              name: "onboardingSteps",
              data: {
                ...state.onBoardingModel.onboardingSteps,
                userObj: {
                  ...state.onBoardingModel.onboardingSteps.userObj,
                  onboardingStep: 4,
                },
              },
            });
            handleNavigate(onBoardingHandler(4));
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async addOnboardingStep3Exit({ payload, handleNavigate }, state) {
      await axiosClient
        .post("auth/updateOnboardingDetails", payload)
        .then((res) => {
          if (res.data.code === 200) {
            Cookies.remove("userDetails");
            handleNavigate("/");
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        })
        .finally(() => {
          this.setData({ name: "isSaveClicked", data: false });
        });
    },
    async uploadUserProfilePic({ payload }) {
      const formData = new FormData();
      formData.append("mentorProfilePic", payload?.mentorProfilePic);
      formData.append("mentorId", payload?.mentorId);
      axiosClient
        .post("auth/uploadUserProfilePic", formData)
        .then((res) => {
          if (res.status === 200) {
            // setattachmentLoading(false);
            if (res?.data?.code === 200) {
              // setAttachment([res?.data?.data?.attachments]);
              // setUploadData({ ...uploadData, attachments: files?.[0]?.name });
            } else {
              // attachmentInputRef.current.value = "";
              dispatch.toastModel.showToast({
                value: "Danger",
                message: res?.data?.msg,
              });
            }
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: "Invalid Upload",
          });
        });
    },
    async getMatchingQuestion({ payload }, state) {
      await axiosClient
        .post("mentor/questionList", payload)
        .then((res) => {
          if (!res.data?.error) {
            const auth = cookieDecryption("userDetails");
            const { result, schemaObject, formatedData } = stepsObjectMaker({
              data: res.data.data.data,
              categorySequence: res.data.data.categorySequence,
              role: auth.role,
            });
            const payload = {
              basicSteps: result,
              questionSchema: schemaObject,
              formatedData,
            };
            this.setData({ name: "stepsFormData", data: payload });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async getStateData() {
      await axiosClient
        .get("auth/getStateList")
        .then((res) => {
          if (res.data.code === 200) {
            let states = (res?.data?.data || []).reduce((acc, cur) => {
              return [...acc, { label: cur, value: cur }];
            }, []);
            this.setData({ name: "stateList", data: states });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async getSchoolOrInstitute({ payload, setschool, listValue }) {
      await axiosClient
        .post("auth/schoolOrInstituteList", payload)
        .then((res) => {
          if (res.data.code === 200) {
            let schoolOrInstituteLabel = (
              res?.data?.data?.SchoolOrInstitute || []
            ).reduce((acc, cur) => {
              return [...acc, { label: cur, value: cur }];
            }, []);
            const schoolOrInstitute = (
              res?.data?.data?.SchoolOrInstitute || []
            ).sort((a, b) => a.localeCompare(b));
            this.setData({
              name: "schoolOrInstitute_label",
              data: schoolOrInstituteLabel,
            });
            this.setData({
              name: "schoolOrInstitute",
              data: res?.data?.data?.SchoolOrInstitute,
            });
            if (setschool) {
              const filteredOptions = res?.data?.data?.SchoolOrInstitute.filter(
                (item) => !listValue.includes(item)
              );
              setschool(filteredOptions);
            }
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async updateSignUp({ handleNavigate, isExit }) {
      await axiosClient
        .get("auth/completeRegistration")
        .then((res) => {
          if (res.data.code === 200) {
            if (isExit) {
              removeCookie("userDetails");
              handleNavigate("/");
            } else {
              handleNavigate("/basic-info");
            }
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async exitSignUp({ handleNavigate, isExit }) {
      await axiosClient
        .get("auth/completeRegistration")
        .then((res) => {
          if (res.data.code === 200) {
            if (isExit) {
              removeCookie("userDetails");
              handleNavigate("/");
            } else {
              handleNavigate("/basic-info");
            }
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async applicationComplete({ handleNavigate, isExit }, state) {
      await axiosClient
        .get("auth/applicationComplete")
        .then((res) => {
          if (res.data.code === 200) {
            dispatch.authModel.getUserDetails({});
            let auth = cookieDecryption("userDetails");
            auth = {
              ...auth,
              onboardingStep: 5,
              userObj: { ...auth.userObj, onboardingStep: 5 },
              // timezone:{...auth.userObj.timezone}
            };
            cookieEncryption("userDetails", auth);
            dispatch.authModel.setUserDetails({
              name: "logInUser",
              data: auth,
            });
            this.setData({
              name: "onboardingSteps",
              data: {
                ...state.onBoardingModel.onboardingSteps,
                userObj: {
                  ...state.onBoardingModel.onboardingSteps.userObj,
                  onboardingStep: 5,
                },
              },
            });
            dispatch.authModel.getUserDetails({});
            handleNavigate("/home");
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async addNewSchoolOrInstituteInApp({payload}) {
      return axiosClient
        .post("auth/addNewSchoolOrInstituteInApp" ,payload)
        .then((res) => {
          if (res.data.code === 200) {
          }
        })
        .catch((err) => console.log(err));
    },
    async getAllSchoolOrInstitute() {
      return axiosClient
        .get("auth/all/schoolOrInstituteList")
        .then((res) => {
          if (res.data.code === 200) {
            this.setData({
              name: "schoolOrInstitute",
              data: res?.data?.data,
            });

          }
        })
        .catch((err) => console.log(err));
    },
  }),
};

export default onBoardingModel;
