import { routePermission } from '../../utils';
import store from '../../rematch';
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';

const ViewLayout = ({ children }) => {
  const location = useLocation();
  const { dispatch } = store;
  const receiverId = useParams();

  useEffect(() => { 
    if (routePermission?.some(path => window.location.pathname.includes(path)) && receiverId && window.innerWidth < 580) {
      dispatch.homeModel.setData({ name: "resizeWidth", data: true })
    } else {
      dispatch.homeModel.setData({ name: "resizeWidth", data: false })
    }
  }, [location])


  return children
}

export default ViewLayout
