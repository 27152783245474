import { jsonToFormData } from "../../utils";
import axiosClient from "../../api/axiosClient";

const messagesModel = {
  state: {
    isErrorOccured:false,
    connectionList: [],
    messageList: [],
    receiverUser: {},
    sharedContentList: [],
    chatId: "",
    uploadProgress:0,
    fileName:"",
    imageMessageList:[],
    imageMessageData:"",
    replyMessage:null,
    sokcetChatId:'',
    // userChId: ""
  },
  reducers: {
    setMsgState(state, payload) {
      return {
        ...state,
        [payload.name]: payload.data,
      };
    },
  },
  effects: (dispatch) => ({
    
    async fileUpload({ payload, type,setProgress }) {
      this.setMsgState({name:"uploadProgress",data:0})
      this.setMsgState({name:"fileName",data:""})
      let formObj = {
        messageFile: payload?.messageFile,
        mentorId: payload?.mentorId,
      };
      this.setMsgState({name:"fileName",data:payload?.messageFile})
      await axiosClient
        .post("/mentor/uploadMessageFile", jsonToFormData(formObj),{onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          const percentage = Math.round((100 * data.loaded) / data.total)
          this.setMsgState({name:"uploadProgress",data:percentage})
        },})
        .then(async (res) => {
          if (res.data.code === 200) {
            const messagePayload = {
              event: "sendMsg",
              data: {
                file: res?.data?.data?.file,
                fileKey: res?.data?.data?.fileKey,
                messageType: type,
                duration: payload?.duration,
                ...(payload?.groupId && {groupId:payload?.groupId}),
                chatType: payload?.chatType,
                chatId: payload?.chatId,
                ...(payload?.receiverId && { receiverId: payload?.receiverId })
              },
            };
            dispatch.socketModel.sendEvent({ payload: messagePayload });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  }),
};

export default messagesModel;
