import PrivateLayout from "../layout/PrivateLayout";
import PublicLayout from "../layout/PublicLayout";
import { memo } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { cookieDecryption, onBoardingHandler } from "../utils";
import ViewLayout from "../layout/ViewLayout";
import ResizeLayout from "../layout/ResizeLayout";

const PrivateRouter = ({ title, allowedRoles, layout, isBack, className }) => {
  const auth = cookieDecryption("userDetails");
  const location = useLocation();
  const loggedRole = auth?.role || auth?.userObj?.role;
  const onboardingStep =
    auth?.onboardingStep === 0
      ? auth?.onboardingStep
      : auth?.onboardingStep || auth?.userObj?.onboardingStep;
  const isAccess = allowedRoles.find((role) => role === loggedRole);

  if (auth && isAccess && onboardingStep === 5) {
    return (
      <Wrapper
        layout={layout}
        title={title}
        className={className}
        isBack={isBack}
      />
    );
  } else if ((onboardingStep || onboardingStep === 0) && onboardingStep !== 5) {
    return (
      <Navigate
        to={onBoardingHandler(onboardingStep)}
        state={{ from: location }}
        replace
      />
    );
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};

const getLayout = (layout, routeData) => {
  switch (layout) {
    case "PUBLIC_LAYOUT": {
      return (
        <PublicLayout>
          <ViewLayout>
            <ResizeLayout>
              <Outlet />
            </ResizeLayout>
          </ViewLayout>
        </PublicLayout>
      );
    }
    default: {
      return (
        <PrivateLayout routeData={routeData}>
          <ViewLayout>
            <ResizeLayout>
              <Outlet />
            </ResizeLayout>
          </ViewLayout>
        </PrivateLayout>
      );
    }
  }
};

const Wrapper = ({ title, layout, ...rest }) => (
  <HelmetProvider context={{}}>
    <title>{title}</title>
    {getLayout(layout, { title, ...rest })}
  </HelmetProvider>
);

export default memo(PrivateRouter);
