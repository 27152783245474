import { Navigate } from "react-router-dom";
import axiosClient from "../../api/axiosClient";

const menteesModel = {
  state: {
    isnotification: false,
    menteesUserList: [],
    mentorsUserList: [],
    sort: {
      mentee: { sort: "", order: "" },
      location: { sort: "", order: "" },
      school: { sort: "", order: "" },
      joinDate: { sort: "", order: "" },
    },
    pagination: {
      limit: 10,
      page: 1,
      pages: 1,
      total: 0,
    },
  },
  reducers: {
    setData(state, payload) {
      return { ...state, ...payload };
    },
    setPage(state, payload) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: payload,
        },
      };
    },
    setPageLimit(state, payload) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          limit: payload,
          page: 1,
        },
      };
    },
    setStateData(state, payload) {
      return {
        ...state,
        [payload.name]: payload.data,
      };
    },
    setSortOrder: (state, payload) => {
      return { ...state, sort: { ...state.sort, ...payload } };
    },
  },
  effects: (dispatch) => ({
    async getMentees(payload) {
      await axiosClient
        .post("mentor/getMenteesList", payload)
        .then((res) => {
          if (res.status === 200) {
            const { docs, limit, page, pages, total } = res.data.data;
            this.setData({
              pagination: { limit, page, pages, total },
              isMenteesList: true,
              menteesUserList: docs,
            });
            this.setStateData({
              name: "pagination",
              data: { limit, page, pages, total },
            });
          }
          else{
            <Navigate to="/unauthorized" replace />
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
  }),
};

export default menteesModel;
