import React, { useEffect, useState } from "react";
import { sms_notification, Close1 } from "../../../utils/images";

const SentEmailPopup = ({ isPopupVisible, setPopupVisible }) => {
  // <img src={Close1} alt="Close" onClick={hidePopup} />
  return (
    <div className={`forgot_model_popup`}>
      <div className="model_popup_detail">
        <h6>We have sent an Email</h6>
        <div className="flex justify-center" >
          <img src={sms_notification} alt="sms-notification" />
        </div>
        <p>Please check your email inbox or spam to get forgot password link</p>
      </div>
    </div>
  );
};

export default SentEmailPopup;
