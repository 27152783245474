import { Canedar_icon, Close } from "../../../utils/images";
import { useState, useEffect, memo } from "react";

const TimeSlot = (props) => {
  const { toastList, autoDelete, autoDeleteTime } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, autoDeleteTime);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, autoDelete, autoDeleteTime, list]);

  const deleteToast = (id) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    const toastListItem = toastList.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  };
  const renderData = (toast) => {
    switch (toast?.title) {
      case "Danger": {
        return (
          <>
            <h5>Slots not available</h5>
            <p>Try adjusting your date to book your time slot</p>
          </>
        );
      }
      case "Orientation": {
        return (
          <>
            <h5>Oops! There is no date for orientation available</h5>
            <p>You may Contact Us or click Continue below to proceed.</p>
          </>
        );
      }
      case "Screening": {
        return (
          <>
            <h5>Oops! There is no date for screening available</h5>
            <p>You may Contact Us or click Continue below to proceed.</p>
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  };
  return (
    <>
      <div className="slot_popup">
        {list.map((toast, i) => {
          return (
            <div className="slot_wrapper">
              <img
                src={Close}
                alt=""
                className="close_btn cursor-pointer"
                onClick={() => deleteToast(toastList[0].id)}
              />
              <div className="slot_box">
                <span>
                  <img src={Canedar_icon} alt="" />
                </span>

                <div className="slot_info">{renderData(toast)}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default memo(TimeSlot);
