import * as z from "zod";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import countryListJson from "./country-list.json";
import languageListJson from "./language-list.json";
import { getAudioDuration } from "./audioHandler";
const CryptoJS = require("crypto-js");
const socketIo = require("socket.io-client");

const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
export const expireTime = () => new Date(Date.now() + oneDay); // Token expiry time
// export const expireTime = () => new Date(Date.now() + 8 * (60 * 60 * 1000)); // Token expiry time
export const getAccessToken = () => cookieDecryption("userDetails")?.token;
export const isAuthenticated = () => !!getAccessToken();

export const Encryption = (data) => {
  try {
    return CryptoJS.AES.encrypt(
      data,
      process.env.REACT_APP_SECRET_KEY
    ).toString();
  } catch (e) {}
};

export function Decryption(data) {
  try {
    let bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (e) {}
}

export const cookieEncryption = (key, data) => {
  try {
    if (key && data) {
      let ciphertext = Encryption(JSON.stringify(data));
      Cookies.set(key, ciphertext, { path: "/", expires: expireTime() });
    }
  } catch (e) {}
};

export const cookieDecryption = (key) => {
  try {
    const data = Cookies.get(key);
    let decryptedData = null;
    if (key && data) {
      decryptedData = Decryption(data);
    }
    return decryptedData;
  } catch (e) {}
};

export function Success(msg) {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
  });
}

export function Error(msg) {
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
  });
}

export const removeCookie = (key) => {
  Cookies.remove(key, { path: "/", expires: expireTime() });
};

export const onBoardingHandler = (onboardingStep) => {
  switch (onboardingStep) {
    case 0: {
      return "/signup-success";
    }
    case 1: {
      return "/basic-info";
    }
    case 2: {
      return "/additional-info";
    }
    case 3: {
      return "/matching-questions";
    }
    case 4: {
      return "/application-complete";
    }
    case 5: {
      return "/home";
    }
  }
};

export const stepByPath = (path) => {
  switch (path) {
    case "/signup-success": {
      return 0;
    }
    case "/basic-info": {
      return 1;
    }
    case "/additional-info": {
      return 2;
    }
    case "/matching-questions": {
      return 3;
    }
    case 4: {
      return "/application-complete";
    }
    case 5: {
      return "/home";
    }
  }
};

export const extractKeys = (data) => {
  const keys = [];

  const traverse = (item) => {
    if (item.key) {
      keys.push(item.key);
    }

    if (item.childrens && Array.isArray(item.childrens)) {
      item.childrens.forEach(traverse);
    }
  };

  data.forEach(traverse);
  return keys;
};

export const getFilteredCountry = () => {
  const filter = (options) => {
    return options?.filter((option) => option?.cname?.toLowerCase());
  };

  const filteredOptions = filter(languageListJson).map((country) => {
    return {
      value: country.iso,
      label: `${country.cname} (+${country.code})`,
      country: country,
      svg: country?.svg,
    };
  });

  return filteredOptions;
};
export const handleLogOut = () => {
  const socket = socketIo(process.env.REACT_APP_SOCKET_API_KEY, {
    transports: ["websocket"],
    // query: `user=${token}`,
  });
  Cookies.remove("userDetails", { path: "/" });
  localStorage.setItem("userStatus", "logged Out");
  socket?.close();
};

function buildFormData(formData, data, parentKey) {
  // console.log("type", data);
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

export const getDuration = (duration, date) => {
  let seconds = duration.asSeconds();
  let minutes = duration.asMinutes();
  let hours = duration.asHours();
  let days = duration.asDays();
  let months = duration.asMonths();
  let years = duration.asYears();
  if (minutes <= 1440) {
    // return `Today, ${moment(date).format("h:mm A")}`;
    return `Today, ${UTCToPartnerTimeZone(date, { utc: "UTC" }).format(
      "h:mm A"
    )}`;
  } else if (days < 2) {
    // return `${days?.toString()?.split(".")[0]} Day, ${moment(date).format( "h:mm A")}`;
    return `${days?.toString()?.split(".")[0]} Day, ${UTCToPartnerTimeZone(
      date,
      { utc: "UTC" }
    ).format("h:mm A")}`;
  } else if (days > 1) {
    // return `${days?.toString()?.split(".")[0]} Days, ${moment(date).format( "h:mm A")}`;
    return `${days?.toString()?.split(".")[0]} Days, ${UTCToPartnerTimeZone(
      date,
      { utc: "UTC" }
    ).format("h:mm A")}`;
  }
  // if (minutes <= 1440) {
  //   return `Today, ${UTCToPartnerTimeZone(date,{utc:"UTC"}).format("h:mm A")}`;
  // } else if (days < 2) {
  //   return `${days?.toString()?.split(".")[0]} Day, ${UTCToPartnerTimeZone(date,{utc:"UTC"}).format(
  //     "h:mm A"
  //   )}`;
  // } else if (days > 1) {
  //   return `${days?.toString()?.split(".")[0]} Days, ${UTCToPartnerTimeZone(date,{utc:"UTC"}).format(
  //     "h:mm A"
  //   )}`;
  // }
};

export const getDurationDate = (duration, date) => {
  let seconds = duration.asSeconds();
  let minutes = duration.asMinutes();
  let hours = duration.asHours();
  let days = duration.asDays();
  let months = duration.asMonths();
  let years = duration.asYears();

  if (minutes >= 525600) {
    return `${years.toFixed()} year ago`;
  } else if (months >= 1) {
    if (months >= 2) {
      return `${months.toFixed()} months ago`;
    }
    return `${months.toFixed()} month ago`;
  } else if (days < 2) {
    if (seconds < 60) {
      return "now";
    } else if (minutes <= 1) {
      return `${minutes?.toString()?.split(".")[0]} min ago`;
    } else if (minutes > 1 && minutes < 60) {
      return `${minutes?.toString()?.split(".")[0]} mins ago`;
    } else if (minutes >= 60 && minutes < 120) {
      return `${hours?.toString()?.split(".")[0]} hour ago`;
    } else if (minutes >= 120 && minutes < 1440) {
      return `${hours?.toString()?.split(".")[0]} hours ago`;
    } else {
      return `${days?.toString()?.split(".")[0]} day ago`;
    }
  } else if (days > 1) {
    return `${days?.toString()?.split(".")[0]} days ago`;
  }
};

// export function getTimeFormat(time) {
//     let x = moment();
//     let y = moment(time);
//     let duration = moment.duration(x.diff(y));
//     let day = duration.asDays();
//     if (day < 1) {
//       return moment(time).format("hh:mm A");
//       // return UTCToPartnerTimeZone(time,timezone).format("hh:mm A");
//     } else {
//       return moment(time).format("MM/DD/YYYY");
//       // return UTCToPartnerTimeZone(time,timezone).format("MM/DD/YYYY");
//     }
//   }


export function getTimeFormat(time,timezone) {
  let x = moment();
  let y = moment(time);
  let duration = moment.duration(x.diff(y));
  let x1 = moment().tz("UTC");
  let y1 = moment(time).tz(timezone?.utc)?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  let duration1 = moment.duration(x1.diff(y1));
  // debugger
  let day = duration1.asDays();
  if (day < 1) {
    return UTCToPartnerTimeZone(time,timezone)?.format("hh:mm A");
  } else {
    return UTCToPartnerTimeZone(time,timezone)?.format("MM/DD/YYYY");
  }
}

export const audioErrorHandler = (error) => {
  switch (error.name) {
    case "AbortError": //error from navigator.mediaDevices.getUserMedia
      console.log("An AbortError has occured.");
      break;
    case "NotAllowedError": //error from navigator.mediaDevices.getUserMedia
      console.log(
        "A NotAllowedError has occured. User might have denied permission."
      );
      break;
    case "NotFoundError": //error from navigator.mediaDevices.getUserMedia
      console.log("A NotFoundError has occured.");
      break;
    case "NotReadableError": //error from navigator.mediaDevices.getUserMedia
      console.log("A NotReadableError has occured.");
      break;
    case "SecurityError": //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
      console.log("A SecurityError has occured.");
      break;
    case "TypeError": //error from navigator.mediaDevices.getUserMedia
      console.log("A TypeError has occured.");
      break;
    case "InvalidStateError": //error from the MediaRecorder.start
      console.log("An InvalidStateError has occured.");
      break;
    case "UnknownError": //error from the MediaRecorder.start
      console.log("An UnknownError has occured.");
      break;
    default:
      console.log("An error occured with the error name " + error.name);
  }
};

function isValidURL(url) {
  // Regular expression pattern to match a URL
  var urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

  // Check if the given URL matches the pattern
  return urlPattern.test(url);
}

export function getFileTypeFromUrl(url) {
  if (!isValidURL(url)) {
    return;
  }

  const parsedUrl = new URL(url);

  const pathname = parsedUrl.pathname;
  const extension = pathname.substring(pathname.lastIndexOf(".")).toLowerCase();

  const mimeType = extensionToMimeType(extension);
  return mimeType;
}

function extensionToMimeType(extension) {
  // Define mappings for common extensions to MIME types
  const extensionToMime = {
    ".jpg": "image/jpeg",
    ".jpeg": "image/jpeg",
    ".png": "image/png",
    ".gif": "image/gif",
    ".mp3": "audio/mpeg",
    ".mp4": "video/mp4",
    ".pdf": "application/pdf",
    ".doc": "application/msword", // Microsoft Word document
    ".docx":
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Microsoft Word document (OpenXML)
    ".ppt": "application/vnd.ms-powerpoint", // Microsoft PowerPoint presentation
    ".pptx":
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // Microsoft PowerPoint presentation (OpenXML)
    ".xls": "application/vnd.ms-excel", // Microsoft Excel spreadsheet
    ".xlsx":
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Microsoft Excel spreadsheet (OpenXML)
    ".csv": "text/csv", // Comma-separated values
    // Add more extensions and MIME types as needed
  };

  return extensionToMime[extension] || "application/octet-stream"; // Default to binary if not found
}

export function formatSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const parts = [];

  if (hours > 0) {
    parts.push(`${hours.toString().padStart(2, "0")}`);
  }

  parts.push(`${minutes.toString().padStart(2, "0")}`);
  parts.push(`${remainingSeconds.toString().padStart(2, "0")}`);

  return parts.join(":");
}

export function sanitizeKey(inputString) {
  const specialChars = {
    "(": 40,
    ")": 41,
    "/": 47,
    "?": 63,
    "[": 91,
    "]": 93,
    "'": 39,
    ",": 44,
    ".": 46,
  };

  let sanitizedString = "";
  for (let char of inputString) {
    if (specialChars[char] !== undefined) {
      sanitizedString += `_${specialChars[char]}`;
    } else {
      sanitizedString += char;
    }
  }

  return sanitizedString;
}

export function retrieveOriginalValue(sanitizedString) {
  const specialChars = {
    40: "(",
    41: ")",
    47: "/",
    63: "?",
    91: "[",
    93: "]",
    39: "'",
    44: ",",
    46: ".",
  };

  let originalValue = "";
  let i = 0;
  while (i < sanitizedString.length) {
    if (sanitizedString[i] === "_" && i + 3 < sanitizedString.length) {
      const asciiValue = parseInt(sanitizedString.substring(i + 1, i + 4), 10);
      if (!isNaN(asciiValue) && specialChars[asciiValue] !== undefined) {
        originalValue += specialChars[asciiValue];
        i += 4;
        continue;
      }
    }
    originalValue += sanitizedString[i];
    i++;
  }

  return originalValue;
}

export const generateCustomAvatar = (text1, text2) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Draw background
  context.fillStyle = stringToHslColor(text1);
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Draw text
  context.font = "normal 75px Montserrat_medium";
  context.fillStyle = "#fff";
  context.textAlign = "center";
  context.textBaseline = "middle";

  let first = text1?.charAt(0)?.toUpperCase();
  let last = text2?.charAt(0)?.toUpperCase();

  if (!last) {
    last = "U";
  }
  if (!first) {
    first = "S";
    last = "U";
  }

  context.fillText(first + last, canvas.width / 2, canvas.height / 2);

  return canvas.toDataURL("image/png");
};

export const stringToHslColor = (str, s = 30, l = 80) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
};

function extractFileName(inputString) {
  const parts = inputString.split("-");
  if (parts.length > 0) {
    const fileNameWithExtension = parts[parts.length - 1];
    const fileName = fileNameWithExtension.substring(
      0,
      fileNameWithExtension.lastIndexOf(".")
    );
    return fileName.replace(/-/g, " ");
  } else {
    return null; // No valid file name found
  }
}

export function getFilenameFromPath(filePath) {
  if (!filePath) {
    return;
  }

  const parts = filePath?.split("/");
  const lastPart = parts[parts?.length - 1];
  return extractFileName(lastPart);
}

const questionTypes = {
  "Single Choice": "MatchingRadio",
  "Multi Choice": "MULTICHECKBOX",
  "Language Select": "LUNGAGE_SELECT",
  "Drop Down Option": "DROP_DOWN_OPTION",
  "Drop Down Multi Select": "SCHOOL_SELECT",
};

export const stepsObjectMaker = ({ data, categorySequence, role }) => {
  let schemaObject = {};
  let formated = data.reduce(
    (acc, cur) => {
      if (!cur?.category) {
        return acc;
      }
      return {
        ...acc,
        res: [
          ...acc.res,
          {
            type: questionTypes[cur.queType],
            label: cur.question,
            key:
              cur?.queType === "Drop Down Multi Select"
                ? cur._id
                : `${sanitizeKey(cur.question || "")}`,
            required: true,
            options: cur.option || [],
            category: cur.category,
            orderNum: cur.orderNum,
            answer: cur.answer,
            id: cur._id,
            placeholder:
              cur?.queType === "Drop Down Option"
                ? "Select"
                : cur?.queType === "Drop Down Multi Select"
                ? "Select School"
                : null,
            // className: cur?.queType === "Drop Down Option"?"w-full":null
          },
        ],
        category: { ...acc.category, [cur.category]: cur.category },
      };
    },
    { res: [], category: {} }
  );
  if (role === "Mentor") {
    formated = {
      ...formated,
      res: [
        ...formated.res,
        {
          type: "MatchingRadio",
          label: "Can you mentor a student in a language other than English?",
          key: `${sanitizeKey(
            "Can you mentor a student in a language other than English?"
          )}`,
          required: true,
          category: "Personality & Interests",
          orderNum: 4,
          answer: [],
          id: `${sanitizeKey(
            "Can you mentor a student in a language other than English?"
          )}`,
          options: [
            { option: "Yes", optionNum: 1 },
            { option: "No", optionNum: 2 },
          ],
          childrens: [
            {
              type: "LUNGAGE_SELECT",
              label:
                "What are the languages (other than English) that you speak comfortably?",
              key: `${sanitizeKey(
                "What are the languages (other than English) that you speak comfortably?"
              )}`,
              required: true,
              options: [],
              category: "Personality & Interests",
              orderNum: 4,
              answer: [],
              id: "",
            },
          ],
        },
      ],
    };
  }
  formated = {
    ...formated,
    res: [
      ...formated.res,
      {
        type: "DESCRIPTION_BOX",
        label:
          "Please tell us a little bit about yourself that we couldn't learn from the previous questions. What else do you think we should know to help find you the best possible match? Tell us what makes you different from everyone else!",
        key: `${sanitizeKey(
          "Please tell us a little bit about yourself that we couldn't learn from the previous questions. What else do you think we should know to help find you the best possible match? Tell us what makes you different from everyone else!"
        )}`,
        required: true,
        wrapperClass: "w_100",
        placeholder: "Type your answer here",
        category: "Education Information",
        orderNum: 4,
        answer: [],
        id: `${sanitizeKey(
          "Please tell us a little bit about yourself that we couldn't learn from the previous questions. What else do you think we should know to help find you the best possible match? Tell us what makes you different from everyone else!"
        )}`,
      },
    ],
  };
  let removedCategory = categorySequence.filter(
    (data) => formated.category[data]
  );

  const result = removedCategory.reduce((acc, cur) => {
    const schema = z
      .object({
        ans: z.string(),
        subAns: z
          .array(z.string(), {
            message: "Please select a suboption",
          })
          .nullable(),
      })
      .refine((data) => data.subAns !== null, {
        message: "Subanswer cannot be null",
      });
    // debugger
    let response = [
      ...acc,
      {
        title: cur,
        type: "BASIC_CONTENT",
        childrens: formated.res.reduce((prev, next) => {
          if (next.type !== "LUNGAGE_SELECT") {
            if (next.id) {
              // if(next.id==="6570017ccc9862ab1a55e1f5"){
              //   return prev
              //   debugger;
              // }
              schemaObject[sanitizeKey(next.id)] =
                next.type === "MULTICHECKBOX"
                  ? //  z
                    //       .string({
                    //         required_error: "Please select an option",
                    //       })
                    //       .array()
                    //       .nonempty({
                    //         message: "Please select an option",
                    //       })
                    // z
                    //   .array(schema, {
                    //     required_error: "Please select an option",
                    //     invalid_type: "Invalid type for the array",
                    //   })
                    //   .nonempty({
                    //     message: "Please select an option",
                    //   })

                    z
                      .object(
                        {
                          ans: z.string(),
                          // subAns: z.array(z.string(), {
                          //   message: "Please select a suboption",
                          // }),
                        },
                        { required_error: "Please select an option" }
                      )
                      .array()
                      .nonempty({
                        message: "Please select an option",
                      })
                  : next.type === "DESCRIPTION_BOX"
                  ? z
                      .string({
                        required_error: "Please enter answer",
                      })
                      .min(1, { message: "Please enter answer" })
                  : next.type === "DROP_DOWN_OPTION"
                  ? z
                      .object(
                        {
                          ans: z.string(),
                          // subAns: z.array(z.string(), {
                          //   message: "Please select a suboption",
                          // }),
                        },
                        { required_error: "Please select an option" }
                      )
                      .array()
                      .nonempty({
                        message: "Please select an option",
                      })
                  : next.type === "SCHOOL_SELECT"
                  ? z
                      .object(
                        {
                          ans: z.string(),
                        },
                        { required_error: "Please select atleast one school" }
                      )
                      .array()
                      .nonempty({
                        message: "Please select atleast one school",
                      })
                  : z
                      .object(
                        {
                          ans: z.string({
                            required_error: "Please select an option",
                          }),
                        },
                        {
                          required_error: "Please select an option",
                        }
                      )
                      .array()
                      .nonempty({
                        message: "Please select an option",
                      });
            }
          } else {
            schemaObject[sanitizeKey(next.label)] = z
              .string({
                required_error: "Please select an option",
              })
              .array()
              .nonempty();
            // z
            //   .object(
            //     {
            //       ans: z.string(
            //         {
            //           required_error: "Please select an optdgfdgfion",
            //         },
            //         { nonempty: "fshdgfsjkdfhfgfdgfdgsjkd" }
            //       ),
            //       // subAns: z.array(z.string(), {
            //       //   message: "Please select a suboption",
            //       // }),
            //     },
            //     {
            //       required_error: "Please sfdgfdgdfgdfgfdgdfelect an option",
            //     },
            //     { nonempty: "jkdfghkdghdkghfkgdfgfdgdhgkfd" }
            //   )
            //   .array()
            //   .nonempty(
            //     {
            //       message: "Please select an option",
            //     },
            //     { required_error: "Please select an option" }
            //   );
            // z
            //   .object({
            //     ans: z.string({
            //       required_error: "Please select an option",
            //     }),
            //   })
            //   .array()
            //   .refine(
            //     (data) => {
            //       debugger
            //       return data.every((item) => item.ans.trim() !== ""); // Ensure every ans is not empty
            //     },
            //     {
            //       message: "Please select an option for every item",
            //     }
            //   );
          }

          if (next.category === cur) {
            return [
              ...prev,
              {
                type: "ROW",
                childrens: [next],
              },
            ];
          } else {
            return prev;
          }
        }, []),
      },
    ];
    return response;
  }, []);
  return { result, schemaObject, formatedData: formated.res };
};

export async function blobToMP3(blob) {
  const mp3Blob = new Blob([blob], { type: "audio/mp3" });
  // Create a new File object from the Blob
  const mp3File = new File([mp3Blob], "audio.mp3", {
    type: "audio/mp3",
  });
  const duration = await getAudioDuration(mp3Blob);

  return { file: mp3File, duration };
}
export const percentage_to_display = (percentage) => {
  return `${(Number.isNaN(Number(percentage)) ? 0 : Number(percentage))
    .toFixed(2)
    .replace(/\.00$/, "")} %`;
};

export const routePermission = [
  "/messages/",
  "/mentees/",
  "/progress/",
  "/home/",
  "/content/",
  "/events/",
];
export function formatMessageDate(inputDate, timezone) {
  const date = moment(inputDate).tz(timezone?.utc);
  const today = moment().tz(timezone?.utc).startOf('day');
  const yesterday = moment().tz(timezone?.utc).subtract(1, 'days').startOf('day');

  if (date.isSame(today, "day")) {
    return `Today ${date.format("hh:mm A")}`;
  } else if (date.isSame(yesterday, "day")) {
    return `Yesterday ${date.format("hh:mm A")}`;
  } else {
    return `${date.format("ddd, MMM D [at] hh:mm A")}`;
  }
}
export const emojis = {
  Heart: "❤️",
  Smile: "😂",
  Sad: "😞",
  DisBelief: "😅",
  ThumsUp: "👍",
};
export const emojisRegex =
  /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~\u00C0-\u017F]+$/;

export const UTCToPartnerTimeZone = (date, timezone) => {
  const test = moment.utc(date).tz(timezone?.utc);
  return moment.utc(date).tz(timezone?.utc);
};

export const checkTimeZoneDateEvents = (date, timezone) => {
  let dateTime = new Date(date);

  let convertedTimeforListing = dateTime.toLocaleDateString("en-US", {
    timeZone: timezone?.utc,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  });
  let convertedTimeforForm = dateTime.toLocaleTimeString("en-US", {
    timeZone: timezone?.utc,
    hour: "numeric",
    minute: "numeric",
  });

  return { convertedTimeforListing, convertedTimeforForm };
};

export const UTCToFormattedPartnerTimeZone = (date, timezone) =>
  moment.utc(date).tz(timezone?.utc).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

export function PartnerTimeZoneToUTC(date, timezone) {
  var m = moment.tz(date, "MM/DD/YYYY h:mm A", timezone?.utc).utc().format("");
  return m;
}
