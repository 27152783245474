import {
  profile,
  down_arrow,
  settings_icon,
  logout_icon,
} from "../../../utils/images";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { generateCustomAvatar } from "../../..//utils";
import { Skeleton } from "../../../components/ui/skeleton";
const UserMenu = ({ logout, userData, handleSettingOpen, loading }) => {
  const { preferredFname = "", preferredLname = "", userObj = {} } = userData;
  const fullName = preferredFname + " " + preferredLname;

  return (
    <Popover>
      {loading ? (
        <>
          <div className=" d_flex_center cursor_pointer">
            <span className="profle_img mr-6">
              <Skeleton className="min-h-[40px] min-w-[40px] rounded-full " />
            </span>
            <p className="user_name">
              <Skeleton className="min-h-[15px] min-w-[120px] mr-6 mb-3" />
              <span>
                <Skeleton className="min-h-[10px] max-w-[80px] mr-6" />
              </span>
            </p>
            <img src={down_arrow} alt="" />
          </div>
        </>
      ) : (
        <PopoverTrigger asChild>
          <div className="profile_info d_flex_center cursor_pointer">
            <span className="profle_img">
              <img
                src={
                  userData?.profilePic
                    ? userData?.profilePic
                    : generateCustomAvatar(preferredFname,preferredLname)
                }
                alt=""
              />
            </span>
            <p className="user_name">
              {fullName}
              <img src={down_arrow} alt="" />
              <span>{userData.role}</span>
            </p>
          </div>
        </PopoverTrigger>
      )}

      <PopoverContent className="header_fixed_box">
        <PopoverClose asChild>
          <div className="header_main_box">
            <div className="header_menu_list">
              <div
                class="sub_account d_flex align_items_center"
                onClick={() => handleSettingOpen()}
              >
                <span>
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Settings" clip-path="url(#clip0_56_29861)">
                      <path
                        id="Vector"
                        d="M10.1389 3.71529C10.6122 1.76418 13.3878 1.76418 13.8611 3.71529C13.9321 4.0084 14.0714 4.2806 14.2675 4.50973C14.4636 4.73886 14.711 4.91846 14.9896 5.03389C15.2682 5.14933 15.5702 5.19734 15.8709 5.17403C16.1716 5.15072 16.4625 5.05673 16.72 4.89973C18.4344 3.85529 20.3978 5.81751 19.3533 7.53306C19.1966 7.79046 19.1027 8.08122 19.0795 8.3817C19.0562 8.68219 19.1042 8.98392 19.2195 9.26238C19.3348 9.54083 19.5142 9.78815 19.743 9.98423C19.9719 10.1803 20.2438 10.3196 20.5367 10.3908C22.4878 10.8642 22.4878 13.6397 20.5367 14.1131C20.2436 14.1841 19.9714 14.3233 19.7422 14.5194C19.5131 14.7155 19.3335 14.9629 19.2181 15.2416C19.1026 15.5202 19.0546 15.8221 19.0779 16.1228C19.1012 16.4235 19.1952 16.7145 19.3522 16.972C20.3967 18.6864 18.4344 20.6497 16.7189 19.6053C16.4615 19.4485 16.1707 19.3547 15.8703 19.3314C15.5698 19.3082 15.268 19.3561 14.9896 19.4714C14.7111 19.5867 14.4638 19.7661 14.2677 19.995C14.0716 20.2239 13.9323 20.4958 13.8611 20.7886C13.3878 22.7397 10.6122 22.7397 10.1389 20.7886C10.0679 20.4955 9.92864 20.2233 9.73254 19.9942C9.53644 19.765 9.28901 19.5854 9.01039 19.47C8.73176 19.3546 8.42982 19.3066 8.12913 19.3299C7.82844 19.3532 7.5375 19.4472 7.28 19.6042C5.56556 20.6486 3.60222 18.6864 4.64667 16.9708C4.80345 16.7134 4.89728 16.4227 4.92054 16.1222C4.9438 15.8217 4.89583 15.52 4.78052 15.2415C4.66522 14.9631 4.48584 14.7158 4.25697 14.5197C4.02809 14.3236 3.75618 14.1843 3.46333 14.1131C1.51222 13.6397 1.51222 10.8642 3.46333 10.3908C3.75644 10.3198 4.02864 10.1806 4.25778 9.9845C4.48691 9.7884 4.6665 9.54097 4.78194 9.26234C4.89738 8.98371 4.94539 8.68177 4.92208 8.38108C4.89876 8.08039 4.80478 7.78946 4.64778 7.53195C3.60333 5.81751 5.56556 3.85418 7.28111 4.89862C8.39222 5.57418 9.83222 4.9764 10.1389 3.71529Z"
                        stroke="#1E2D66"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        id="Vector_2"
                        d="M8.66797 12.2518C8.66797 13.1358 9.01916 13.9837 9.64428 14.6088C10.2694 15.2339 11.1172 15.5851 12.0013 15.5851C12.8854 15.5851 13.7332 15.2339 14.3583 14.6088C14.9834 13.9837 15.3346 13.1358 15.3346 12.2518C15.3346 11.3677 14.9834 10.5199 14.3583 9.89477C13.7332 9.26965 12.8854 8.91846 12.0013 8.91846C11.1172 8.91846 10.2694 9.26965 9.64428 9.89477C9.01916 10.5199 8.66797 11.3677 8.66797 12.2518Z"
                        stroke="#1E2D66"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_56_29861">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0 0.251953)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <div class="u_name">
                  <h6>Settings</h6>
                </div>
              </div>
              <div
                class="sub_account d_flex align_items_center"
                onClick={() => logout()}
              >
                {/* <img src={logout_icon} alt="Logout" /> */}
                <span>
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Logout" clip-path="url(#clip0_56_29787)">
                      <path
                        id="Vector"
                        d="M14.2222 7.67833V5.45611C14.2222 4.86674 13.9881 4.30151 13.5713 3.88476C13.1546 3.46801 12.5893 3.23389 12 3.23389H4.22222C3.63285 3.23389 3.06762 3.46801 2.65087 3.88476C2.23413 4.30151 2 4.86674 2 5.45611V18.7894C2 19.3788 2.23413 19.944 2.65087 20.3608C3.06762 20.7775 3.63285 21.0117 4.22222 21.0117H12C12.5893 21.0117 13.1546 20.7775 13.5713 20.3608C13.9881 19.944 14.2222 19.3788 14.2222 18.7894V16.5672"
                        stroke="#1E2D66"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        id="Vector_2"
                        d="M8.66797 12.1224H22.0013L18.6679 8.78906"
                        stroke="#1E2D66"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        id="Vector_3"
                        d="M18.668 15.4559L22.0013 12.1226"
                        stroke="#1E2D66"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_56_29787">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0 0.122559)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <div class="u_name">
                  <h6>Log out</h6>
                </div>
              </div>
            </div>
          </div>
        </PopoverClose>
      </PopoverContent>
    </Popover>
  );
};

export default UserMenu;
