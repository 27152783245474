import moment from "moment";
import axiosClient from "../../api/axiosClient";

const progressConnectionModel = {
  state: {
    connectionList: [],
    sort: {
      sort: "",
      order: "",
    },
    pagination: {
      limit: 10,
      page: 1,
      pages: 1,
      total: 0,
    },
    modalStatus: {
      isModelOpen: false,
      AllBadges: false,
      SelectBadges: false,
    },
    menteeDetails: {},
    badges: [],
    menteeBadgeDetails: [],
    systemBadgeData :[],
    assignProjectList: [],
    menteeWiseProject:[],
  },
  reducers: {
    setData: (state, payload) => {
      return { ...state, ...payload };
    },
    setModalStatus: (state, payload) => {
      return { ...state, modalStatus: { ...state.modalStatus, ...payload } };
    },
    setSortOrder: (state, payload) => {
      return { ...state, sort: { ...state.sort, ...payload } };
    },
    setPage(state, payload) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: payload,
        },
      };
    },
    setPageLimit(state, payload) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          limit: payload,
          page: 1,
        },
      };
    },
    getIndividualMentee(state, payload) {
      const menteeDetails = state.connectionList.filter(
        (mentee) => mentee._id === payload.userId
      )[0];
      return { ...state, menteeDetails };
    },
  },
  effects: (dispatch) => ({
    async getMentees(payload) {
      await axiosClient
        .post("progress/getMenteesList", payload)
        .then((res) => {
          if (res.status === 200) {
            const { docs, limit, page, pages, total } = res.data.data;
            this.setData({
              pagination: { limit, page, pages, total },
              isMenteesList: true,
              connectionList: docs,
            });
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    async getMenteeBadgeDetail({payload,systemBadgesData}) {
      await axiosClient
        .post("/progress/getMenteeProgressDetail", payload)
        .then((res) => {
          if (res.status === 200) {
            const systemBadges = res.data.data.badgeDetail.filter(
              (badge) => badge.type === "System"
            );
            if (systemBadges.length > 0 && systemBadgesData) {
              let updatedBadgesDetails = systemBadgesData.map((badge) => {
                const matchingBadge = systemBadges.find(
                  (badgeDetailItem) => badgeDetailItem.badgeName === badge.title
                );
                if (matchingBadge) {
                  return {
                    ...badge,
                    status: "Unlocked",
                    isAchieved: true,
                    achievedDate: moment(matchingBadge.achievedDate).format("MM/DD/YY"),
                  };
                } else {
                  return badge;
                }
              });
              dispatch.progressConnectionModel.setData({systemBadgeData:[...updatedBadgesDetails]})
            }
            const { badgeDetail } = res.data.data;
            this.setData({
              menteeBadgeDetails: [...badgeDetail],
            });
            this.setModalStatus({
              isModelOpen: true,
            });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
    async getBadgesList(payload) {
      await axiosClient
        .post("/progress/givenBadgeList",payload)
        .then((res) => {
          if (res.status === 200) {
            const { badge } = res.data.data;
            const authorisedbadges = badge.filter((badge)=>!badge.isSend)
            this.setData({
              badges: [...authorisedbadges],
            });
            this.setModalStatus({
              AllBadges: true,
            });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
    async sendBadge(payload) {
      await axiosClient
        .post("/progress/sendBadge", payload)
        .then((res) => {
          if (res.status === 200) {
            this.setModalStatus({
              SelectBadges: false,
              isModelOpen: false,
              AllBadges: false,
            });
            this.getIndividualMentee({ userId: payload.userId });
            dispatch.toastModel.showToast({
              value: "Success",
              message: res.data.msg,
            });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
    async getMentor(payload) {
      await axiosClient
        .post("/mentee/getMentorList", payload)
        .then((res) => {
          if (res.status === 200) {
            const { docs, limit, page, pages, total } = res.data.data;
            this.setData({
              pagination: { limit, page, pages, total },
              isMentorList: true,
              connectionList: docs,
            });
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    async getmenteesAllProject() {
      await axiosClient
        .get("/progress/menteesAllProject")
        .then((res) => {
          if (res.status === 200) {
            // const { docs, limit, page, pages, total } = res.data.data;
            this.setData({
              assignProjectList: res.data.data.assignProjectList,
            });
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    async getMenteeWiseProject(payload) {
      await axiosClient
        .post("/progress/menteeWiseProject", payload)
        .then((res) => {
          if (res.status === 200) {
           this.setData({menteeWiseProject:res.data.data.assignProjectList})
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          // dispatch.toastModel.showToast({
          //   value: "Danger",
          //   message: err.message,
          // });
        });
    },
  }),
};
export default progressConnectionModel;
