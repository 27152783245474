import {
  covertUTCtolocal,
  getDurationInHoursMinutes,
} from "../../pages/PrematchTodo";
import axiosClient from "../../api/axiosClient";
import moment from "moment";
const MentorData = [
  {
    id: 0,
    step: "Step 1",
    title: "Complete your application",
    completedTitle: "Complete your application",
  },
  {
    id: 1,
    step: "Step 2",
    title: "Schedule Orientation",
    completedTitle: "Orientation Scheduled",
  },
  {
    id: 2,
    step: "Step 3",
    title: "Complete Screening",
    completedTitle: "Screening Scheduled",
  },
  {
    id: 3,
    step: "Step 4",
    title: "Complete Training",
    completedTitle: "Training Completed",
  },
];
const MenteeData = [
  {
    id: 0,
    step: "Step 1",
    title: "Complete your application",
    completedTitle: "Complete your application",
  },
  {
    id: 1,
    step: "Step 2",
    title: "Complete Onboarding",
    completedTitle: "Complete Onboarding",
  },
];
const preMatchModel = {
  state: {
    preMatchDetails: {},
    timeSlotAvailabe: [],
    selectedTimeSlotDuration: "",
    mentorTraining: {},
    redirectURLWin: "",
    preMatchEventList: [],
  },
  reducers: {
    setData(state, payload) {
      return { ...state, [payload.name]: payload.data };
    },
    settimeSlotDuration(state, payload) {
      return { ...state, selectedTimeSlotDuration: payload };
    },
  },
  effects: (dispatch) => ({
    async getPreMatchDetails({ setValue, key }) {
      await axiosClient
        .get("/mentor/getPreMatchDetail")
        .then((res) => {
          if (res.data.code === 200) {
            const userDetails = res.data.data.userObj;
            this.setData({ name: "preMatchDetails", data: res.data.data });
            if (setValue && userDetails) {
              let start_time = moment
                .utc(userDetails?.[key]?.start_date)
                .local()
                .format("h:mm A");
              let end_time = moment
                .utc(userDetails?.[key]?.end_date)
                .local()
                .format("h:mm A");
              const duration = getDurationInHoursMinutes(
                userDetails?.[key]?.start_date,
                userDetails?.[key]?.end_date
              );
              const formattedDate = covertUTCtolocal(
                userDetails?.[key]?.start_date,
                "DATE"
              );
              setValue("dateInput", formattedDate);
              setValue("time_slot", {
                value: userDetails?.[key]?._id,
                id: userDetails?.[key]?._id,
                label: `${start_time} - ${end_time} (${userDetails?.[
                  key
                ]?.userId?.legalFname.trim()} ${
                  userDetails?.[key]?.userId?.legalLname
                })`,
              });
              this.settimeSlotDuration(duration);
            }
            const result = res.data.data.userObj.ShowedPreMatchStep?.map(
              (id, index) => {
                const mentorItem = MentorData.find(
                  (item) => item.id + 1 === id
                );
                return mentorItem
                  ? {
                      id: index,
                      step: `Step ${index + 1}`,
                      title: mentorItem.title,
                      completedTitle: mentorItem.completedTitle,
                    }
                  : null;
              }
            );
            this.setData({ name: "preMatchData", data: result });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async getTimeSlot({
      payload,
      handleSelectChange,
      userDetails,
      key,
      handleNavigate,
    }) {
      await axiosClient
        .post("mentor/getTimeSlot", payload)
        .then((res) => {
          if (res.data.code === 200) {
            this.setData({ name: "timeSlotAvailabe", data: res.data.data });
            if (res.data.data.length == 0) {
              dispatch.toastModel.showTimeSlot({
                value: "Danger",
              });
            } else {
              dispatch.toastModel.showTimeSlot({
                value: "Break",
              });
            }
          } else {
            if (!res?.data?.data?.isAvailable) {
              dispatch.toastModel.showTimeSlot({
                value: key,
              });
              handleNavigate("/home/pre-match-todo");
            } else {
              dispatch.toastModel.showToast({
                value: "Danger",
                message: res.data.msg,
              });
            }
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async postDetails({ payload, handleNavigate, key }) {
      await axiosClient
        .post("mentor/mentorPreMatchToDoList", payload)
        .then((res) => {
          if (res.data.code === 200) {
            if (payload?.step === 5) {
              handleNavigate("/home/congratulation");
            } else {
              dispatch.toastModel.showToast({
                value: "Success",
                message: key
                  ? `${key} has been successfully scheduled`
                  : `Your Training is complete`,
              });
              handleNavigate("/home/pre-match-todo");
            }
          } else {
            dispatch.toastModel.showTimeSlot({
              value: key,
            });
            handleNavigate("/home/pre-match-todo");
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async getMentorTraining() {
      await axiosClient
        .get("/mentor/getMentorTraining")
        .then((res) => {
          if (res.data.code === 200) {
            const { redirectURL } = res.data.data;
            let redirectURLWin = window.open(redirectURL, "_blank");
            this.setData({
              name: "redirectURLWin",
              data: redirectURLWin,
            });
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async getMenteePreMatchDetails({ setValue }) {
      await axiosClient
        .get("/mentee/getPreMatchDetail")
        .then((res) => {
          if (res.data.code === 200) {
            const userDetails = res.data.data.userObj;
            this.setData({ name: "preMatchDetails", data: res.data.data });
            if (setValue) {
              setValue("rulesandregulation", userDetails.parentalConsent);
            }
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async postMenteeDetails({ payload, handleNavigate }) {
      await axiosClient
        .post("mentee/menteePreMatchToDoList", payload)
        .then((res) => {
          if (res.data.code === 200) {
            if (payload?.step === 4) {
              handleNavigate("/home/congratulation");
            } else {
              handleNavigate("/home/pre-match-todo");
              if(!payload?.isDraft){

                dispatch.toastModel.showToast({
                  value: "Success",
                  message: "Onboarding Successful!",
                });
              }
            }
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
    async getPreMatchEventList({ payload, setisDiplayed }) {
      await axiosClient
        .post("mentor/getPreMatchEventList", payload)
        .then((res) => {
          if (res.data.code === 200) {
            this.setData({ name: "preMatchEventList", data: res.data.data });
            // if (payload.type === "Screening") {
            if (res.data.data?.length > 0) {
              setisDiplayed(true);
            } else {
              dispatch.toastModel.showTimeSlot({
                value: payload.type,
              });
            }
            // }
          } else {
            dispatch.toastModel.showToast({
              value: "Danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          dispatch.toastModel.showToast({
            value: "Danger",
            message: err.message,
          });
        });
    },
  }),
};

export default preMatchModel;
